import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                    },
                    children:
                      'PART C: Information that may or may not need to be established, depending on whether the property is affected or impacted by the issue in question.',
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(15.6) Are you aware of any of the following building safety issues that affect the property: this is non-exhaustive list:',
                    },
                    children: [
                      {
                        $formkit: 'el-checkbox-group',
                        name: `${parentPath}.building_safety_issues`,
                        attrs: { class: 'flex flex-col ' },
                        options: [
                          { label: 'Unsafe cladding', value: 'Unsafe cladding' },
                          {
                            label: 'Integrity of building materials used in construction (e.g., asbestos)',
                            value: 'Asbestos',
                          },
                          {
                            label: 'Risk of collapse (e.g., damaged roofs or structural failures)',
                            value: 'Risk of collapse',
                          },
                          {
                            label: 'At-Risk wooden decking for external structures (including balconies)',
                            value: 'At-Risk wooden decking',
                          },
                          { label: 'Lack of emergency lighting where required', value: 'Lack of emergency lighting' },
                          {
                            label: 'Insufficient fire/smoke alarm systems',
                            value: 'Insufficient fire/smoke alarm systems',
                          },
                          { label: 'Other : Please specify', value: 'Other' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_safety_issues.length > 0`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(15.6.1) Please provide details regarding the building safety issue that you are referring to?',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.building_safety_issues_details`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: `${isTrueCondition(`${parentPath}.building_safety_issues`)} && ${isTrueCondition(`${parentPath}.building_safety_issues.includes('Asbestos')`)}`,
                    $el: 'div',
                    attrs: {
                      class: 'pl-6',
                    },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(15.6.2) Please confirm if a management plan is in place and supply a copy with this form (15.6) Integrity of building materials used in construction (e.g., asbestos)',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.management_plan_in_place`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: ['Attached', "Can't locate", 'To Follow'],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.management_plan_in_place === 'Attached'`),
                        $cmp: 'el-form-item',
                        props: { label: '(15.6.2.1) Please attach form.', required: true, class: 'pl-6' },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.management_plan_in_place_form`,
                            fileName: `${parentPath}.management_plan_in_place_form`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_safety_issues.length > 0`),
                    $el: 'div',
                    attrs: {
                      class: 'pl-6',
                    },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(15.6.3) Has remediation work been completed?',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.remediation_work_completed`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: ['Yes', 'No'],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.remediation_work_completed === 'Yes'`),
                        $cmp: 'el-form-item',
                        props: {
                          label: '(15.6.3.2) Please provide details?',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.remediation_work_completed_details`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            inputType: 'textarea',
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.remediation_work_completed === 'Yes'`),
                        $el: 'div',
                        children: [
                          {
                            $el: 'div',
                            attrs: { class: 'pl-6' },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(15.6.3.1) Please provide evidence of completion', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.evidence_of_completion`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: ['Attached', "Can't locate", 'To Follow'],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.evidence_of_completion === 'Attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(15.6.3.1.1) Please attach evidence of completion', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.evidence_of_completion_files`,
                                    fileName: `${parentPath}.evidence_of_completion_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        if: `$: ${isTrueCondition(`${parentPath}.remediation_work_completed === 'No'`)} && $formData.data.additional_questions.building_safety_issues && $formData.data.additional_questions.building_safety_issues.length`,
                        $el: 'div',
                        children: [
                          {
                            $el: 'div',
                            children: '(15.6.3.1) Please provide information as to the following',
                            attrs: {
                              class: 'formkit-label mb-4',
                            },
                          },
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(15.6.3.1.1) What is the defect/hazard?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.defect_hazard`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(15.6.3.1.2) What work needs to be done?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.work_needs_to_be_done`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(15.6.3.1.3) What work has already been done?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.work_has_already_been_done`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(15.6.1.4) What will the potential cost be to the new purchaser, if known?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.potential_cost_to_new_purchaser`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(15.6.3.1.5) Will it affect the buyer’s ability to reside within the property? (i.e., will they need to move into temporary accommodation whilst works are undertaken?)',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.affect_buyer_reside_temporary_accommodation`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
