import { isTrueCondition } from '~/schemas/components/common/helpers'
export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: 'Adjacent Land or Property',
            attrs: {
              class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
            },
          },
          {
            $cmp: 'ElFormItem',
            props: {
              label: '(1.4) Is any part of the property on a separate site or separate title number eg garden, outbuilding, parking, garage?',
              class: 'text-lg font-medium mb-4 mt-4 bold-label',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.is_separate_site`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: { gutter: 50 },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    if: isTrueCondition(`${parentPath}.is_separate_site === "yes"`),
                    $el: 'div',
                    attrs: {
                      class: 'pl-6',
                    },
                    children: [
                      {
                        $cmp: 'ElFormItem',
                        props: {
                          label: '(1.4.1) Have you bought this other adjacent land/property during your ownership that will be included in the sale?',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.adjacent_land_bought`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.adjacent_land_bought === "yes"`),
                        $el: 'div',
                        children: [
                          {
                            $cmp: 'ElFormItem',
                            props: {
                              label: '(1.4.1.1) What is the title number for the separate land?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.adjacent_land_title_number`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                          {
                            $cmp: 'ElFormItem',
                            props: {
                              label: '(1.4.1.2) Do you have an office copy for the separate title?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.adjacent_land_office_copy`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.adjacent_land_office_copy === 'attached'`),
                            $cmp: 'ElFormItem',
                            props: {
                              label: '(1.4.1.2.1) Please kindly attach the office copy for the separate title',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.adjacent_land_office_copy_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                                fileName: `${parentPath}.adjacent_land_office_copy_files`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: { gutter: 50 },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    if: isTrueCondition(`${parentPath}.is_separate_site === "no"`),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'ElFormItem',
                        props: {
                          label: '(1.4.1) Do the boundaries differ from those shown on the title plan?',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.adjacent_land_boundaries_differ`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.adjacent_land_boundaries_differ === "yes"`),
                        $el: 'div',
                        children: [
                          {
                            $cmp: 'ElFormItem',
                            props: {
                              label: '(1.4.1.1) How do they differ?',
                              class: 'pl-6',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.adjacent_land_boundaries_differ_clarify`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                inputType: 'textarea',
                                rows: 6,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
