import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(4.7) Is the property (or any part of the property) a listed building, or in a conservation area?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.listed_building_or_conservation_area`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.listed_building_or_conservation_area === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.7.1) Is your property a listed building in England or Wales', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.listed_building`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.listed_building === 'yes'`),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(4.7.1.1) Please provide the listing building relevant documents', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.listed_building_documents`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Attached', value: 'attached' },
                              { label: "Can't locate", value: 'cantLocate' },
                              { label: 'Did not obtain', value: 'didNotObtain' },
                              { label: 'To Follow', value: 'toFollow' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.listed_building_documents === 'attached'`),
                        $cmp: 'el-form-item',
                        props: { label: '(4.7.1.1.1) Please upload the relevant document(s)', required: true },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.listed_building_documents_files`,
                            fileName: `${parentPath}.listed_building_documents_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.listed_building_or_conservation_area === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.7.2) Is your property in a designated conservation area?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.conservation_area`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.conservation_area === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.7.2.1) Please provide a copy of the relevant conservation area documents', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.conservation_area_documents`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.conservation_area_documents === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.7.2.1.1) Please upload the relevant document(s)', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.conservation_area_documents_files`,
                                fileName: `${parentPath}.conservation_area_documents_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
