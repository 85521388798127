import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(8.4) Do you know if any of the following rights or arrangements benefit the property?' },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.property_benefits`,
                attrs: { class: 'flex flex-col' },
                options: [
                  { label: 'A public right of way', value: 'public_right_of_way' },
                  { label: 'Rights of light or support for other properties', value: 'rights_of_light_or_support' },
                  {
                    label:
                      'Rights created through custom or use (eg rights to graze on other land or forage, rights of the lord of the manor or others to take things from your property etc)',
                    value: 'custom_or_use',
                  },
                  { label: 'Mines and minerals under the property', value: 'mines_and_minerals' },
                  { label: 'Liability to contribute to the maintenance of a church chancel', value: 'liability_to_contribute' },
                  { label: 'Other', value: 'other' },
                ],
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'pl-6' },
            children: [
              {
                $cmp: 'el-form-item',
                props: { label: '(8.4.1) Rights of light', required: true },
                if: `${isTrueCondition(`${parentPath}.property_benefits`)} && ${isTrueCondition(`${parentPath}.property_benefits.includes('rights_of_light_or_support')`)}`,
                children: [
                  {
                    $formkit: 'el-input',
                    inputType: 'textarea',
                    name: `${parentPath}.rights_of_light`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    rows: 6,
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                if: `${isTrueCondition(`${parentPath}.property_benefits`)} && ${isTrueCondition(`${parentPath}.property_benefits.includes('rights_of_light_or_support')`)}`,
                props: { label: '(8.4.2) Rights of support from neighbouring property', required: true },
                children: [
                  {
                    $formkit: 'el-input',
                    inputType: 'textarea',
                    name: `${parentPath}.rights_of_support`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    rows: 6,
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                if: `${isTrueCondition(`${parentPath}.property_benefits`)} && ${isTrueCondition(`${parentPath}.property_benefits.includes('custom_or_use')`)}`,
                props: { label: '(8.4.3) Customary rights (e.g. rights from the usage of the property)', required: true },
                children: [
                  {
                    $formkit: 'el-input',
                    inputType: 'textarea',
                    name: `${parentPath}.customary_rights`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    rows: 6,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
