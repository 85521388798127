import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                    },
                    children: 'Central Heating (including electricity, or mains gas, liquid gas or oil)',
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(12.3) Is there full/partial central heating in your property?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.central_heating`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(12.3.1) Please indicate whether the heating system is electric, mains gas, liquid gas or oil.',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-checkbox-group',
                        name: `${parentPath}.central_heating_type`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Electric', value: 'electric' },
                          { label: 'Mains Gas', value: 'mains_gas' },
                          { label: 'Liquid Gas', value: 'liquid_gas' },
                          { label: 'Oil', value: 'oil' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating === 'yes'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.3.2) Was the central heating system installed after 31 March 2005?', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.central_heating_installed_after_31_march_2005`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating_installed_after_31_march_2005 === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(12.3.2.1) Please supply a copy of the appropriate gas safety boiler completion certificate e.g. CORGI (Certificate of Compliance/ Gas Safety Notification Certificate/ Building Regulation Certificate) or if you do not have the certificate, provide the exceptional circumstance form.',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.central_heating_installed_after_31_march_2005_certificate`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating_installed_after_31_march_2005_certificate === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.3.2.1.1) Please upload the evidence', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.central_heating_installed_after_31_march_2005_certificate_files`,
                        fileName: `${parentPath}.central_heating_installed_after_31_march_2005_certificate_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(12.3.3) Is the heating system in good working order to your satisfaction?',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.central_heating_working_order`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating === 'yes'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.3.4) When was the heating system last serviced or maintained?', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.central_heating_last_serviced`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating === 'yes'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.3.5) Please provide a copy of the service or maintenance works report', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.central_heating_last_serviced_report`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                          { label: 'N/A', value: 'notApplicable' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.central_heating_last_serviced_report === 'attached'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(12.3.5.1) Please upload the evidence',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.central_heating_last_serviced_report_files`,
                        fileName: `${parentPath}.central_heating_last_serviced_report_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
