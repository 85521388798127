import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isBaspiOnly(),
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElFormItem',
            props: {
              label:
                '(2.6) Are you aware of any other material issue, significant incidents or information which relates to the property or has anything occurred which may affect the average person’s decision to proceed?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.material_issue`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.material_issue === 'yes'`),
            $cmp: 'ElFormItem',
            props: {
              label: '(2.6.1) Please kindly provide details of any issues that you are aware of?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.material_issue_clarify`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                inputType: 'textarea',
                rows: 6,
              },
            ],
          },
        ],
      },
    ],
  }
}
