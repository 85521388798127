import { isBaspiOnly, isFalseCondition, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(13.1) Electricity Provider',
                      class: 'mb-0',
                      required: true,
                    },
                    children: [
                      {
                        if: `$formData.data.${parentPath}.electricity_provider_not_connected_checkbox !== true && $formData.data.${parentPath}.electricity_provider_other !== true`,
                        $formkit: 'select-autocomplete',
                        name: `${parentPath}.electricity_provider`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          'British Gas',
                          'EDF Energy',
                          'Scottish Power',
                          'Affect Energy',
                          'Eon',
                          'OVO',
                          'SSE',
                          'Npower',
                          'Utility Warehouse',
                          'Breeze Energy',
                          'Bulb',
                          'Co-op Energy',
                          'Eon Next',
                          'Smartest Energy',
                          'Drax Group',
                          'Ecotricity',
                          'ENGIE',
                          'Octopus Energy',
                          'Bristol Energy',
                          'Shell Energy',
                          'Beam Energy',
                          'Jersey Electricity',
                          'Opus Energy',
                          'Tonik Energy',
                        ],
                      },
                    ],
                  },
                  {
                    $el: 'div',
                    attrs: { class: 'flex gap-4 w-1/2 not-required' },
                    children: [
                      {
                        if: isFalseCondition(`${parentPath}.electricity_provider_other`),
                        $formkit: 'el-checkbox',
                        name: `${parentPath}.electricity_provider_not_connected_checkbox`,
                        text: 'Not connected',
                      },
                      {
                        if: isFalseCondition(`${parentPath}.electricity_provider_not_connected_checkbox`),
                        $formkit: 'el-checkbox',
                        name: `${parentPath}.electricity_provider_other`,
                        text: 'Other',
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    if: isTrueCondition(`${parentPath}.electricity_provider_other`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(13.1.1) Please provide the name of your supplier',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.electricity_provider_name`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        if: `${isTrueCondition(`${parentPath}.electricity_provider_not_connected_checkbox`)}`,
                        $cmp: 'ElRow',
                        props: {
                          gutter: 20,
                        },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: {
                                  label:
                                    '(13.1.1) Please clarify why the property is not connected and the date when this was disconnected or the date that this will be connected?',
                                  class: 'pl-6',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.electricity_provider_not_connected`,
                                    inputType: 'textarea',
                                    rows: 6,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        if: `${isTrueCondition(`${parentPath}.electricity_provider`)} && ${isFalseCondition(`${parentPath}.electricity_provider_not_connected_checkbox`)}`,
                        $cmp: 'ElRow',
                        props: {
                          gutter: 20,
                        },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: {
                                  label: '(13.1.1) Please describe the location of the Electric Meter',
                                  class: 'pl-6',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.electricity_provider_meter_location_description`,
                                    inputType: 'textarea',
                                    rows: 6,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  {
                    if: isBaspiOnly(),
                    $el: 'div',
                    children: [
                      {
                        if: `${isTrueCondition(`${parentPath}.electricity_provider`)} && ${isFalseCondition(`${parentPath}.electricity_provider_not_connected_checkbox`)}`,
                        $cmp: 'el-form-item',
                        props: {
                          label: '(13.1.1) Please provide a photograph of the location of any electricity meter',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.electricity_provider_meter_location_photo`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Attached', value: 'attached' },
                              { label: 'To Follow', value: 'toFollow' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.electricity_provider_meter_location_photo === 'attached'`),
                        $cmp: 'el-form-item',
                        props: { label: '(13.1.1.1) Please upload the photo', class: 'pl-6', required: true },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.electricity_provider_meter_location_photo_files`,
                            fileName: `${parentPath}.electricity_provider_meter_location_photo_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isFalseCondition(`${parentPath}.electricity_provider_not_connected_checkbox`),
                    $cmp: 'el-form-item',
                    props: { label: '(13.1.2) Is there electric vehicle charging at the property', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.electric_vehicle_charging`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
