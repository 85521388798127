import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElFormItem',
            props: {
              label: '(2.3) Are you aware of any restrictions on the use or alteration of the property which have not been complied with?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.restrictions`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.restrictions === 'yes'`),
            $cmp: 'ElFormItem',
            props: {
              label: '(2.3.1) Please kindly provide details of the restrictions and/or alteration that has not been complied with?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.restrictions_clarify`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                inputType: 'textarea',
                rows: 6,
              },
            ],
          },
        ],
      },
    ],
  }
}
