export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(14.4) Please confirm that on completion, you will ensure:',
              required: true,
            },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.on_completion_ensure`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  {
                    label:
                      '(14.4.1) that you will remove all rubbish and items not included in the sale from the property including its garden, loft and any sheds or outbuildings.',
                    value: 'remove_rubbish',
                  },
                  { label: '(14.4.2) that the property is left in a clean and tidy condition?', value: 'clean_tidy' },
                  {
                    label: '(14.4.3) that any light fittings that have been removed will be replaced with a ceiling rose, flex, bulb holder and bulb;',
                    value: 'replace_light_fittings',
                  },
                  { label: '(14.4.4) that reasonable care will be taken when removing any fittings or contents;', value: 'reasonable_care' },
                  {
                    label: '(14.4.5) that keys to all windows and doors and details of any alarm code will be left at the property or with the estate agent.',
                    value: 'keys_left',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
