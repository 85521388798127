import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isBaspiOnly(),
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(2.10) Please confirm the capacity in which in the Seller sells',
              required: true,
            },
            children: [
              {
                $formkit: 'el-checkbox-group',
                attrs: { class: 'flex flex-col' },
                name: `${parentPath}.seller_capacity`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Legal Owner', value: 'legal_owner' },
                  {
                    label: 'Personal representative for deceased owner',
                    value: 'personal_representative_for_deceased_owner',
                  },
                  { label: 'Under power of attorney', value: 'under_power_of_attorney' },
                  { label: 'Mortgagee in possession', value: 'mortgagee_in_possession' },
                  { label: 'Other', value: 'other' },
                ],
              },
            ],
          },
          {
            if: `${isTrueCondition(`${parentPath}.seller_capacity.length > 0`)} && ${isTrueCondition(`${parentPath}.seller_capacity.includes('other')`)}`,
            $cmp: 'el-form-item',
            props: { label: '(2.10.1) Please give details', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-input',
                inputType: 'textarea',
                name: `${parentPath}.seller_capacity_other_details`,
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
