import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: `${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Sewage Treatment Plant'`)}`,
            $el: 'div',
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label: `$: "(12.9) Please state when the " + ${`$formData.data.${parentPath}.property_applicable_septic_tank`} + " was installed"`,
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-date-picker',
                    name: `${parentPath}.property_applicable_septic_tank_installed_year`,
                    inputType: 'year',
                    format: 'YYYY',
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.property_applicable_septic_tank_installed_year >= 1991`),
                $el: 'div',
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(12.9.1) Please supply the Building Regulations approval or equivalent.',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_sewage_treatment_plant_building_regulations_approval`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_sewage_treatment_plant_building_regulations_approval === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.9.1.1) Please upload the evidence', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.property_sewage_treatment_plant_building_regulations_approval_files`,
                        fileName: `${parentPath}.property_sewage_treatment_plant_building_regulations_approval_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
