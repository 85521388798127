import { isFalseCondition, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(13.3) Telephone Provider',
              class: 'mb-0',
              required: true,
            },
            children: [
              {
                if: `$formData.data.${parentPath}.telephone_provider_not_connected_checkbox !== true && $formData.data.${parentPath}.telephone_provider_other_checkbox !== true`,
                $formkit: 'select-autocomplete',
                name: `${parentPath}.telephone_provider`,
                options: ['BT', 'Virgin Media', 'Sky', 'TalkTalk', 'Vodafone', 'EE', 'Plusnet', 'Hyperoptic'],
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'flex gap-4 w-1/2' },
            children: [
              {
                if: isFalseCondition(`${parentPath}.telephone_provider_other_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.telephone_provider_not_connected_checkbox`,
                text: 'Not connected',
              },
              {
                if: isFalseCondition(`${parentPath}.telephone_provider_not_connected_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.telephone_provider_other_checkbox`,
                text: 'Other',
              },
            ],
          },
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                if: isTrueCondition(`${parentPath}.telephone_provider_other_checkbox`),
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(13.3.1) Please provide the name of your supplier',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.telephone_provider_name`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        if: isTrueCondition(`${parentPath}.telephone_provider_not_connected_checkbox`),
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(13.3.1) Please clarify why the property is not connected and the date when this was disconnected or the date that this will be connected?',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.telephone_provider_not_connected`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
