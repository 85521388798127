import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-sm italic text-gray-500 mb-6',
                    },
                    children: [
                      {
                        $el: 'h3',
                        children: 'Japanese knotweed',
                        attrs: {
                          class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                        },
                      },
                      {
                        $el: 'span',
                        children: 'Note:',
                        attrs: {
                          class: 'font-bold',
                        },
                      },
                      {
                        $el: 'span',
                        children:
                          ' Japanese knotweed is an invasive plant which can cause damage to the property unless treated fully, which can take several years.',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(7.8) To your knowledge, is the property or neighbouring land, affected by Japanese knotweed or other invasive species? (or if not known, please state ‘not known’)?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.japanese_knotweed`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.japanese_knotweed === 'yes'`),
                    $el: 'div',
                    attrs: { class: 'pl-6' },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.1) Please state whether there is a management plan in place?', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.japanese_knotweed_management_plan`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                              { label: 'Not known', value: 'notKnown' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.japanese_knotweed_management_plan === 'yes'`),
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.1.1) Please provide a copy of the management plan', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.japanese_knotweed_management_plan_documents`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Attached', value: 'attached' },
                              { label: "Can't locate", value: 'cantLocate' },
                              { label: 'Did not obtain', value: 'didNotObtain' },
                              { label: 'To Follow', value: 'toFollow' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.japanese_knotweed_management_plan_documents === 'attached'`),
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.1.1.1) Please upload a copy of the management plan', required: true },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.japanese_knotweed_management_plan_documents_files`,
                            fileName: `${parentPath}.japanese_knotweed_management_plan_documents_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.japanese_knotweed === 'yes'`),
            $el: 'div',
            attrs: {
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.2) Do you have insurance cover linked to the Japanese knotweed management and treatment plan ?', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.japanese_knotweed_insurance`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.japanese_knotweed_insurance === 'yes'`),
                        $cmp: 'ElRow',
                        props: {
                          gutter: 20,
                          class: 'pl-6',
                        },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(7.8.2.1) Please supply a copy of the insurance policy', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.japanese_knotweed_insurance_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.japanese_knotweed_insurance_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(7.8.2.1.1) Please upload a copy of the policy', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.japanese_knotweed_insurance_documents_files`,
                                    fileName: `${parentPath}.japanese_knotweed_insurance_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.3) Have you paid for the eradication of the Japanese knotweed?', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.japanese_knotweed_eradication`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(7.8.4) Do you have a Japanese knotweed 10 year guarantee?', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.japanese_knotweed_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.japanese_knotweed_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: {
                          gutter: 20,
                          class: 'pl-6',
                        },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(7.8.4.1) Please provide a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.japanese_knotweed_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.japanese_knotweed_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(7.8.4.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.japanese_knotweed_guarantee_documents_files`,
                                    fileName: `${parentPath}.japanese_knotweed_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
