import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
            $cmp: 'el-form-item',
            props: {
              label: '(12.5) Please select what is applicable for this property',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.property_applicable_septic_tank`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: ['Septic Tank', 'Cesspit', 'Sewage Treatment Plant'],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
            $cmp: 'el-form-item',
            props: {
              label: '(12.5.1) Please confirm when was the septic tank last upgraded or replaced?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_septic_tank_last_upgraded`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
