import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(6.4) Have you had any difficulty obtaining competitively priced building insurance due to the structure or location of the property or had insurance refused (eg high premiums, excess over £1,500, special conditions etc)?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.difficulty_obtaining_insurance`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.difficulty_obtaining_insurance === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(6.4.1) abnormal rise in premiums', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.abnormal_rise_in_premiums`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(6.4.2) high excesses', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.high_excesses`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(6.4.3) unusual conditions', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.unusual_conditions`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(6.4.4) refused', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.refused`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: `${isTrueCondition(`${parentPath}.abnormal_rise_in_premiums === "yes"`)} || ${isTrueCondition(`${parentPath}.high_excesses === "yes"`)} || ${isTrueCondition(`${parentPath}.unusual_conditions === "yes"`)} || ${isTrueCondition(`${parentPath}.refused === "yes"`)}`,
                    $cmp: 'el-form-item',
                    props: { label: '(6.4.5) Please give details', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.difficulty_obtaining_insurance_details`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
