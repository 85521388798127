import { isTrueCondition } from '~/schemas/components/common/helpers.js'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        if: `${isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`)} && (${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Sewage Treatment Plant'`)} || ${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Septic Tank'`)})`,
        $cmp: 'ElRow',
        props: {
          gutter: 20,
        },
        children: [
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                $el: 'div',
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(12.12) Does the " + ${`$formData.data.${parentPath}.property_applicable_septic_tank`} + " drain into a waterway (lake, river, stream etc)?"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_septic_tank_drain_into_waterway`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_septic_tank_drain_into_waterway === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(12.12.1) Does it comply with the General Binding Rules for discharge into a waterway.',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_septic_tank_drain_into_waterway_comply_general_binding_rules`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
