export default function () {
  return [
    {
      $el: 'h1',
      children:
        "(4.1) Kindly provide the necessary contact information for the following, as applicable: (The landlord could be a private person, a housing association, or a company operated by the tenants. The role of a managing agent might include collecting rent and/or overseeing the property, and this agent could be appointed by either the landlord or the tenants' own management company.)",
      attrs: {
        class: 'text-sm mb-4 mt-4',
      },
    },
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },

      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: '(4.1.1) Please select your Landlord from the dropdown list below.', required: true, class: 'mb-0' },
          children: [
            {
              $formkit: 'landlord-autocomplete',
              if: '$formData.data.contact_details.my_landlord_is_not_in_the_list !== true && $formData.data.contact_details.i_dont_know_my_landlord !== true',
              name: 'contact_details.select_your_landlord',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          attrs: { class: 'flex gap-4 w-1/2' },
          children: [
            {
              $formkit: 'el-checkbox',
              if: '$formData.data.contact_details.i_dont_know_my_landlord !== true',
              name: 'contact_details.my_landlord_is_not_in_the_list',
              text: 'My landlord is not in the list',
              disabled: '$formData.data.contact_details.i_dont_know_my_landlord === true',
            },
            {
              $formkit: 'el-checkbox',
              name: 'contact_details.i_dont_know_my_landlord',
              text: "I don't know my landlord",
            },
          ],
        },
        {
          $el: 'div',
          if: `$formData.data.contact_details.my_landlord_is_not_in_the_list === true`,
          attrs: {
            class: 'section-indent',
          },
          children: [
            {
              $cmp: 'ElFormItem',
              props: { label: "Landlord's Name", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlords_name',
                  placeholder: "Landlord's Name",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Landlord's Address", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  inputType: 'textarea',
                  rows: 4,
                  name: 'contact_details.landlords_address',
                  placeholder: "Landlord's Address",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Landlord's Phone Number", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlords_phone_number',
                  placeholder: "Landlord's Phone Number",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Landlord's Email", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlords_email',
                  placeholder: "Landlord's Email",
                  validation: 'required|email',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(4.1.1.1) Does your landlord use a managing agent?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'contact_details.does_the_landlord_use_a_managing_agent',
              options: ['Yes', 'No', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          if: `$formData.data.contact_details.does_the_landlord_use_a_managing_agent === "Yes"`,
          attrs: {
            class: 'section-indent',
          },
          children: [
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Name", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlord_managing_agents_name',
                  placeholder: "Managing Agent's Name",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Address", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  inputType: 'textarea',
                  rows: 4,
                  name: 'contact_details.landlord_managing_agents_address',
                  placeholder: "Managing Agent's Address",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Phone Number", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlord_managing_agents_phone_number',
                  placeholder: "Managing Agent's Phone Number",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Email", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.landlord_managing_agents_email',
                  placeholder: "Managing Agent's Email",
                  validation: 'required|email',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(4.1.2) Is there a Management Company (e.g. set up by the tenants to discuss the management of the building) who in turn may appoint a Management Agent to collect the rent and services charges etc?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'contact_details.is_there_a_management_company',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.contact_details.is_there_a_management_company === "Yes"',
          props: { label: 'Please select your Management Company from the dropdown list below.', class: 'section-indent mb-0', required: true },
          children: [
            {
              $formkit: 'management-company-autocomplete',
              if: '$formData.data.contact_details.my_management_company_is_not_in_the_list !== true && $formData.data.contact_details.i_dont_know_my_management_company !== true',
              name: 'contact_details.select_your_management_company',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          attrs: { class: 'flex gap-4 w-3/5 section-indent' },
          children: [
            {
              $formkit: 'el-checkbox',
              if: '$formData.data.contact_details.i_dont_know_my_management_company !== true',
              name: 'contact_details.my_management_company_is_not_in_the_list',
              text: 'My Management Company is not in the list',
              disabled: '$formData.data.contact_details.i_dont_know_my_management_company === true',
            },
            {
              $formkit: 'el-checkbox',
              name: 'contact_details.i_dont_know_my_management_company',
              text: "I don't know my Management Company",
            },
          ],
        },
        {
          $el: 'div',
          if: `$formData.data.contact_details.my_management_company_is_not_in_the_list === true`,
          attrs: {
            class: 'section-indent',
          },
          children: [
            {
              $cmp: 'ElFormItem',
              props: { label: "Management Company's Name", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_company_name',
                  placeholder: "Managing Company's Name",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Management Company's Address", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  inputType: 'textarea',
                  rows: 4,
                  name: 'contact_details.managing_company_address',
                  placeholder: "Management Company's Address",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Company's Phone Number", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_company_phone_number',
                  placeholder: "Managing Company's Phone Number",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Company's Email", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_company_email',
                  placeholder: "Managing Company's Email",
                  validation: 'required|email',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.contact_details.is_there_a_management_company === "Yes"',
          props: { label: 'Does your Management Company use a managing agent?', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'contact_details.does_your_management_company_use_a_managing_agent',
              options: ['Yes', 'No', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          if: `$formData.data.contact_details.does_your_management_company_use_a_managing_agent === "Yes"`,
          attrs: {
            class: 'section-indent',
          },
          children: [
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Name", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_agents_name',
                  placeholder: "Managing Agent's Name",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Address", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  inputType: 'textarea',
                  rows: 4,
                  name: 'contact_details.managing_agents_address',
                  placeholder: "Managing Agent's Address",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Phone Number", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_agents_phone_number',
                  placeholder: "Managing Agent's Phone Number",
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: { label: "Managing Agent's Email", required: true },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'contact_details.managing_agents_email',
                  placeholder: "Managing Agent's Email",
                  validation: 'required|email',
                  validationMessages: {
                    required: 'This field is required',
                  },
                  validationVisibility: 'live',
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.contact_details.does_the_landlord_use_a_managing_agent !== "Yes" && $formData.data.contact_details.does_your_management_company_use_a_managing_agent !== "Yes"`,
          props: { label: '(4.1.3) No Managing Agent' },
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: {
            label: '(4.1.4) Is the owner of the Property required to become a director in a management company for the maintenance of shared amenities?',
            class: 'mt-2',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'contact_details.the_owner_of_the_property_required_to_become_a_director_in_a_management_company',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
