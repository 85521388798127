import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isTrueCondition(`${parentPath}.property_flooded === 'yes'`),
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(7.3) Has a Flood Risk Report or a flood search been carried out?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.flood_risk_report`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.flood_risk_report === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(7.3.1) Please supply a copy of Flood Risk Report.', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.flood_risk_report_documents`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: "Can't locate", value: 'cantLocate' },
                  { label: 'To Follow', value: 'toFollow' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.flood_risk_report_documents === 'attached'`),
            $cmp: 'el-form-item',
            props: { label: '(7.3.1.1) Please upload a copy of the guarantee', class: 'pl-6', required: true },
            validation: 'required',
            validationMessages: {
              required: 'This field is required',
            },
            validationVisibility: 'live',
            children: [
              {
                $formkit: 'file-upload',
                name: `${parentPath}.flood_risk_report_documents_files`,
                fileName: `${parentPath}.flood_risk_report_documents_files`,
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
