<script setup>
import { ref, computed } from 'vue'
import { useFormStatusStore } from '~/stores/form-status.js'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  parentProp: {
    type: String,
    required: false,
    default: '',
  },
  prop: {
    type: String,
    required: false,
    default: '',
  },
  headings: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  infoButtonText: {
    type: String,
    required: false,
    default: '',
  },
})

const values = ref([])

const isEditable = computed(() => useFormStatusStore().isEditable)

function addItem() {
  values.value.push({
    title: '',
    value: 'included_in_sale',
    price_if_sold_separately: '',
    comments: '',
  })
}

function removeItem(index) {
  values.value.splice(index, 1)
}
</script>

<template>
  <el-form label-position="top" size="large">
    <div class="grid w-full grid-cols-12 gap-3">
      <div v-for="heading in headings" :key="heading.prop" :class="heading.cols" class="flex items-end p-2 text-xs font-semibold">
        {{ heading.title }}
      </div>
    </div>

    <radio-form-item v-for="item in items" :key="item.prop" :context="context" :title="item.title" :prop="`${prop}.${item.prop}`" />

    <form-kit
      v-model="values"
      :up-control="false"
      :down-control="false"
      :insert-control="false"
      :remove-control="false"
      :add-button="false"
      :name="prop ? `${prop}.other` : 'other'"
      type="repeater"
      min="0"
      class="cursor-pointer odd:bg-gray-50 hover:bg-gray-100"
    >
      <template #default="{ index }">
        <radio-form-item
          :context="context"
          :index="index"
          :parent-prop="parentProp"
          :prop="prop ? `${prop}.other.${index}` : `other.${index}`"
          is-custom
          @remove="removeItem(index)"
        />
      </template>
    </form-kit>

    <div class="mt-6 grid w-full grid-cols-12 gap-3">
      <span v-if="infoButtonText !== ''" class="col-span-7 mt-2 px-2 text-xs">{{ infoButtonText }}</span>
      <el-button type="primary" class="col-span-4 mx-2 md:col-span-2" :disabled="!isEditable" @click="addItem">Add Item</el-button>
    </div>
  </el-form>
</template>
