import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(3.3) Have you been notified by a relevant authority or qualified expert of an on-going health or safety issue with the property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.notified_by_authority_of_ongoing_health_or_safety_issue`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.notified_by_authority_of_ongoing_health_or_safety_issue === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label: '(3.3.1) Please kindly provide details of what the health or safety issue is?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                inputType: 'textarea',
                name: `${parentPath}.notified_by_authority_of_ongoing_health_or_safety_issue_details`,
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
