export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $el: 'h1',
          children: 'The Building Safety Act 2022 provides protections for qualifying leaseholders.',
          attrs: {
            class: 'text-sm font-semibold mt-6',
          },
        },
        {
          $el: 'a',
          children: 'https://www.gov.uk/guidance/building-safety-leaseholder-protections-guidance-for-leaseholders',
          attrs: {
            href: 'https://www.gov.uk/guidance/building-safety-leaseholder-protections-guidance-for-leaseholders',
            target: '_blank',
            class: 'text-sm section-indent mt-4',
          },
        },
        {
          $el: 'h1',
          children: 'A Deed of Certificate confirms whether the leaseholder is eligible for the leaseholder protections.',
          attrs: {
            class: 'text-sm font-semibold mt-4',
          },
        },
        {
          $el: 'a',
          children: 'https://www.gov.uk/guidance/mandatory-information-required-from-leaseholders-and-building-owners',
          attrs: {
            href: 'https://www.gov.uk/guidance/mandatory-information-required-from-leaseholders-and-building-owners',
            target: '_blank',
            class: 'text-sm section-indent mt-4',
          },
        },
        {
          $el: 'div',
        },
        {
          $el: 'a',
          children: 'https://www.gov.uk/guidance/leaseholder-protections-deed-of-certificate-frequently-asked-questions',
          attrs: {
            href: 'https://www.gov.uk/guidance/leaseholder-protections-deed-of-certificate-frequently-asked-questions',
            target: '_blank',
            class: 'text-sm section-indent mt-4',
          },
        },
        {
          $el: 'div',
          attrs: {
            class: 'mb-4',
          },
        },
        {
          $el: 'h1',
          attrs: {
            class: 'mb-4',
          },
          children: 'Building Safety Cladding Deed of Certificate',
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(11.1) Have you been informed of any proposed or completed remediation work on the building?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.have_you_been_informed_of_any_proposed_or_completed_remediation_work_on_the_building',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide full details', class: 'section-indent', required: true },
          if: `$formData.data.building_safety_cladding_deed_cert.have_you_been_informed_of_any_proposed_or_completed_remediation_work_on_the_building === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.provide_full_details_proposed_or_completed_remediation_work_on_the_building_options',
              options: ['Attached', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.building_safety_cladding_deed_cert.have_you_been_informed_of_any_proposed_or_completed_remediation_work_on_the_building === "Yes" && $formData.data.building_safety_cladding_deed_cert.provide_full_details_proposed_or_completed_remediation_work_on_the_building_options === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `enfranchisement.provide_full_details_proposed_or_completed_remediation_work_on_the_building_files`,
              fileName: `enfranchisement.provide_full_details_proposed_or_completed_remediation_work_on_the_building_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(11.2) Is the property in the building 11 metres in height or 5 storeys or above including the ground floor?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor',
              options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'Not Known', value: 'notKnown' },
              ],
              validation: 'required',
              value: '$saleReadyPack.report.complexityScore.complexityData.5.value',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          children: '(11.2.1) Is the lease of the property a ‘qualifying lease’. The law defines a qualifying lease (in S119 of the Building Safety Act 2022).',
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          attrs: {
            class: 'section-indent text-sm required-label font-[500] text-black',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: 'In summary, the lease is over 21 years, was granted before 14 February 2022, you pay a service charge',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.lease_is_over_21_years_granted_before_14_february_2022_you_pay_a_service_charge',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          children: '(11.2.2) AND on 14 February 2022 one of the following applies to you:',
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          attrs: {
            class: 'section-indent text-sm required-label font-[500] text-black',
          },
        },
        {
          $el: 'div',
          children: [
            {
              $el: 'div',
              children: 'a) this property was your MAIN home',
            },
            {
              $el: 'div',
              children: 'b) You did not own more than 3 UK residential properties in total',
            },
          ],
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          attrs: {
            class: 'section-indent text-sm',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: { class: 'section-indent', required: true },
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.did_not_own_more_than_3_uk_residential_properties_in_total',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          $el: 'div',
          attrs: {
            class: 'section-indent text-sm text-black',
          },
          children: [
            {
              $el: 'div',
              children: '(11.2.3) any other reasons you are aware of that makes this a qualifying lease. Please describe.',
              attrs: {
                class: 'required-label font-[500]',
              },
            },
            {
              $el: 'div',
              children: 'Eg. you bought your property since 14 February 2022 but either point a) or b) above was true for the property on that date.',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'building_safety_cladding_deed_cert.any_other_reasons_you_are_aware_of_that_makes_this_a_qualifying_lease',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(11.2.4) Please confirm the height of the building including the ground floor. ', class: 'section-indent', required: true },
          if: `$formData.data.building_safety_cladding_deed_cert.property_in_the_building_11_metres_in_height_or_5_storeys_or_above_including_the_ground_floor === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              attrs: { class: 'block' },
              name: 'building_safety_cladding_deed_cert.confirm_the_height_of_the_building_including_the_ground_floor',
              options: [
                'The building is under 11 metres and under 5 storeys',
                'The building is 11 metres in height or more or has at least 5 storeys',
                'The building is 18 metres in height or more or has at least 7 storeys',
              ],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(11.3) Do you know if there is a Leaseholder Deed of Certificate for this property?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.leasholder_deed_of_certificate_for_this_property',
              options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'Not Known', value: 'notKnown' },
              ],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(11.3.1) Did you complete the Deed of Certificate or was this completed by the previous leaseholder.',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.building_safety_cladding_deed_cert.leasholder_deed_of_certificate_for_this_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.complete_the_deed_of_certificate_or_was_this_completed_by_the_previous_leaseholder',
              options: ['I completed the Deed of Certificate', 'Previous leaseholder completed the Deed of Certificate'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(11.3.1.1) Please provide a the leaseholder deed of certificate and/or any other information received.',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.building_safety_cladding_deed_cert.leasholder_deed_of_certificate_for_this_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.provide_a_copy_of_the_leaseholder_deed_of_certificate_and_or_any_other_information_received_options',
              options: ['Attached', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.building_safety_cladding_deed_cert.leasholder_deed_of_certificate_for_this_property === "Yes" && $formData.data.building_safety_cladding_deed_cert.provide_a_copy_of_the_leaseholder_deed_of_certificate_and_or_any_other_information_received_options === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `building_safety_cladding_deed_cert.provide_a_copy_of_the_leaseholder_deed_of_certificate_and_or_any_other_information_received_options_files`,
              fileName: `building_safety_cladding_deed_cert.provide_a_copy_of_the_leaseholder_deed_of_certificate_and_or_any_other_information_received_options_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(11.4) Have you informed the Landlord/Freeholder that you wish to sell the property?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.info_landlord_freeholder_that_you_wish_to_sell_the_property',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },

        {
          $cmp: 'ElFormItem',
          props: { label: '(11.5) Do you know if there is a Landlord’s Deed of Certificate for this property?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property',
              options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'Not Known', value: 'notKnown' },
              ],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please supply a copy', class: 'section-indent', required: true },
          if: `$formData.data.building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property_options',
              options: ['Attached', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property_options === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: 'building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property_files',
              fileName: `building_safety_cladding_deed_cert.landlords_deed_of_certificate_for_this_property_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: { label: '(11.6) Are you aware of any additional costs payable on sale or letting', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.aware_of_any_additional_costs_payable_on_sale_or_letting',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details of any costs that you are aware of and state what these relate to', class: 'section-indent', required: true },
          if: `$formData.data.building_safety_cladding_deed_cert.aware_of_any_additional_costs_payable_on_sale_or_letting === "Yes" && $formData.is_baspi_visible`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'building_safety_cladding_deed_cert.provide_details_of_any_costs_that_you_are_aware_of_and_state_what_these_relate_to',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: {
            label: '(11.7) Is the owner of the property required to become a director in the management company for the maintenance of shared amenities.',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'building_safety_cladding_deed_cert.property_required_to_become_a_director_in_the_management_company_for_the_maintenance_of_shared_amenities',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
