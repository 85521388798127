import { isTrueCondition } from '~/schemas/components/common/helpers'
export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElFormItem',
            props: {
              label: '(1.6) Are you aware of, or have you received a notice under the Party Wall Act 1996 in respect of any shared or party boundaries?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.party_wall_act_1996`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.party_wall_act_1996 === "yes"`),
            $el: 'div',
            attrs: { class: 'pl-6' },
            children: [
              {
                $cmp: 'ElFormItem',
                props: {
                  label: '(1.6.1) Please kindly supply a copy of the notice that you have received',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.party_wall_act_1996_notice`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    options: [
                      { label: 'Attached', value: 'attached' },
                      { label: "Can't locate", value: 'cantLocate' },
                      { label: 'Did not obtain', value: 'didNotObtain' },
                      { label: 'To Follow', value: 'toFollow' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.party_wall_act_1996_notice === 'attached'`),
                $cmp: 'ElFormItem',
                props: {
                  label: '(1.6.1.1) Please kindly attach the notice that you have received',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.party_wall_act_1996_notice_files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    fileName: `${parentPath}.party_wall_act_1996_notice_files`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
