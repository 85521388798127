import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
            $el: 'div',
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label: `$: "(12.10) Is any part of the " + (${`$formData.data.${parentPath}.property_applicable_septic_tank`} || '____________') + " or the access to it, outside the boundary of the property?"`,
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.property_septic_tank_outside_boundary`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    options: [
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.property_septic_tank_outside_boundary === 'yes'`),
                $el: 'div',
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(12.10.1) Please kindly clarify where the " + ${`$formData.data.${parentPath}.property_applicable_septic_tank`} + " is located and explain how access is obtained to this."`,
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.property_septic_tank_location`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(12.10.2) Please supply a plan showing the location of and how access is obtained to the " + ${`$formData.data.${parentPath}.property_applicable_septic_tank`} + " system."`,
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_septic_tank_location_plan`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_septic_tank_location_plan === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.10.2.1) Please upload the plan', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.property_septic_tank_location_plan_files`,
                        fileName: `${parentPath}.property_septic_tank_location_plan_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
