import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        if: `${isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`)} && (${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Sewage Treatment Plant'`)} || ${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Septic Tank'`)})`,
        $cmp: 'ElRow',
        props: {
          gutter: 20,
        },
        children: [
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                $el: 'div',
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(12.11) Is the " + ${`$formData.data.${parentPath}.property_applicable_septic_tank`} + " registered with the Environment Agency or exempted?"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_septic_tank_registered_with_environment_agency`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'Exempted', value: 'exempted' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_septic_tank_registered_with_environment_agency === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(12.11.1) Please supply a copy of the appropriate permit registered with the to discharge or exemption Environment Agency or certificate)"`,
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_septic_tank_registered_with_environment_agency_certificate`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_septic_tank_registered_with_environment_agency_certificate === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(12.11.1.1) Please upload a copy of the permit', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.property_septic_tank_registered_with_environment_agency_certificate_files`,
                        fileName: `${parentPath}.property_septic_tank_registered_with_environment_agency_certificate_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: `${isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`)} && ${isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Cesspit'`)}`,
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(12.11) Do you have any licences, maintenance agreements, contracts or service agreements in relation to the drainage arrangements?',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.property_licenses_maintenance_agreements`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.property_licenses_maintenance_agreements === 'yes'`),
                        $el: 'div',
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(12.11.1) Please kindly provide a copy of the agreement referred to.',
                              class: 'pl-6',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.property_licenses_maintenance_agreements_documents`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.property_licenses_maintenance_agreements_documents === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(12.11.1.1) Please upload the agreement', class: 'pl-6', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.property_licenses_maintenance_agreements_files`,
                                fileName: `${parentPath}.property_licenses_maintenance_agreements_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
