import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          /* eslint-disable */
          {
            number: '3.1.',
            text: {
              if: `${`$employeeCase.company_code`} === '1'`,
              then: `AVRillo LLP (being the equity partners, currently at 257 Green Lanes, London, N13 4XE or any other address from which the partners may practice in the future). Also referred to in this contract as the  ‘The Company’, ‘Company’, ‘AVRillo’, ‘The Equity Partners’, ‘Equity Partners’, ‘We’, 'Its’, ‘Their’, ‘Us’, ‘Firm’, ‘The Firm’`,
              if: `${`$employeeCase.company_code`} === '2'`,
              then: `AVRillo R&D Ltd (being the equity partners, currently at 257 Green Lanes, London, N13 4XE or any other address from which the partners may practice in the future). Also referred to in this contract as the  ‘The Company’, ‘Company’, ‘AVRillo’, ‘The Equity Partners’, ‘Equity Partners’, ‘We’, 'Its’, ‘Their’, ‘Us’, ‘Firm’, ‘The Firm’`,
              else: `V & M Property Services Ltd (being the equity partners, currently at The Grainger Suite, Dobson House, Regent Centre, Gosforth, Newcastle Upon Tyne, Tyne And Wear, NE3 3PF or any other address from which the partners may practice in the future). Also referred to in this contract as the  ‘The Company’, ‘Company’, ‘V & M’, Equity Partners’, ‘Equity Partners’, ‘We’, 'Its’, ‘Their’, ‘Us’, ‘Firm’, ‘The Firm’`,
            },
          },
          /* eslint-disable */
          {
            number: '3.2.',
            text: `$: "Employee name: <span class='text-primary font-bold'>" + $employeeCase.name + "</span>"`,
          },
          {
            number: '',
            text: `Also referred to in this contract as ‘you’, ‘Employee’, ‘your’ etc.`,
          },
        ]),
      ],
    },
  ]
}
