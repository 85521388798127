import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(4.9) Has spray foam insulation been installed at the property?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.spray_foam_insulation`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.spray_foam_insulation === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(4.9.1) If ‘Yes’, please provide details together with certification from BBA or KIWA and the installation warranty for the work carried out.',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.spray_foam_insulation_details`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.9.2) Please provide a copy of the certification and warranty', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.spray_foam_insulation_documents`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'Did not obtain', value: 'didNotObtain' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.spray_foam_insulation_documents === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(4.9.2.1) Please upload the relevant document(s)', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.spray_foam_insulation_documents_files`,
                        fileName: `${parentPath}.spray_foam_insulation_documents_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
