import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(9.1.1) Do you park on your property?', required: true },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.parking_arrangements`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                attrs: { class: 'flex flex-col', soloChoice: 'none' },
                options: [
                  { label: 'Garage', value: 'garage' },
                  { label: 'Driveway', value: 'driveway' },
                  { label: 'Allocated Space', value: 'allocatedSpace' },
                  { label: 'None', value: 'none' },
                ],
              },
            ],
          },
          {
            if: `${isTrueCondition(`${parentPath}.parking_arrangements`)} && ${isTrueCondition(`${parentPath}.parking_arrangements.includes('allocatedSpace')`)}`,
            $cmp: 'el-form-item',
            props: { label: '(9.1.1.1) Please state the parking space number', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.parking_space_number`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            props: {
              label: '(9.1.1.2) Do you have off-street parking?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.off_street_parking`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.off_street_parking === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(9.1.1.2.1) Is this off-street parking free or on a meter?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.off_street_parking_free_or_metered`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Free', value: 'free' },
                  { label: 'Metered', value: 'meter' },
                ],
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            props: { label: '(9.1.2) Does your property have any parking space below?', required: true },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.parking_arrangements_misc`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                attrs: { class: 'flex flex-col', soloChoice: 'none' },
                options: [
                  { label: 'On street', value: 'onStreet' },
                  { label: "Resident's Permit", value: 'residentsPermit' },
                  { label: 'Metered Parking', value: 'meteredParking' },
                  { label: 'Communal Parking', value: 'communalParking' },
                  { label: 'Controlled Parking Zone', value: 'controlledParkingZone' },
                  { label: 'None', value: 'none' },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
