import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'text-sm ',
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'formkit-label mb-1',
                },
                children: [
                  {
                    $el: 'span',
                    children: '* ',
                    attrs: {
                      class: 'text-red-400',
                    },
                  },
                  '(5.1) Select yes if the property benefits from any of these guarantees and warranties below:',
                ],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'ml-3 italic text-xs',
                },
                children: [
                  {
                    $el: 'div',
                    children: 'a) New Home Warranty',
                  },
                  {
                    $el: 'div',
                    children: 'b) Damp proofing',
                  },
                  {
                    $el: 'div',
                    children: 'c) Timber treatment',
                  },
                  {
                    $el: 'div',
                    children: 'd) Windows, glazed doors, roof windows or roof lights',
                  },
                  {
                    $el: 'div',
                    children: 'e) Electrical works',
                  },
                  {
                    $el: 'div',
                    children: 'f) Roofing',
                  },
                  {
                    $el: 'div',
                    children: 'g) Central heating',
                  },
                  {
                    $el: 'div',
                    children: 'h) Underpinning',
                  },
                  {
                    $el: 'div',
                    children: 'i) Any other guarantees',
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            props: { required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.property_warranties`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_warranties === 'yes'`),
            $el: 'div',
            children: [
              {
                $cmp: 'ElRow',
                props: { gutter: 20, class: 'pl-8' },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.1) Does the property benefit from a valid New Home Warranty', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.new_home_warranty`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.new_home_warranty === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.1.1) Please confirm whether you hold a copy of the warranty', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.new_home_warranty_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.new_home_warranty_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.1.1.1) Please upload a copy of the warranty', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.new_home_warranty_documents_files`,
                                    fileName: `${parentPath}.new_home_warranty_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.1.2) Please confirm the name of the warranty provider and the date of the policy', required: true },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.new_home_warranty_provider`,
                                    inputType: 'textarea',
                                    rows: 6,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.2) Does the property benefit from damp proofing guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.damp_proofing_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.damp_proofing_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.2.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.damp_proofing_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.damp_proofing_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.2.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.damp_proofing_guarantee_documents_files`,
                                    fileName: `${parentPath}.damp_proofing_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.3) Does the property benefit from timber treatment guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.timber_treatment_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.timber_treatment_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.3.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.timber_treatment_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.timber_treatment_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.3.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.timber_treatment_guarantee_documents_files`,
                                    fileName: `${parentPath}.timber_treatment_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(5.1.4) Does the property benefit from windows, glazed doors, roof windows or roof lights guarantee?',
                          class: 'pl-8',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.windows_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.windows_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.4.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.windows_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.windows_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.4.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.windows_guarantee_documents_files`,
                                    fileName: `${parentPath}.windows_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.5) Does the property benefit from electrical works guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.electrical_works_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.electrical_works_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.5.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.electrical_works_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.electrical_works_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.5.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.electrical_works_guarantee_documents_files`,
                                    fileName: `${parentPath}.electrical_works_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.6) Does the property benefit from roofing guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.roofing_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.roofing_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.6.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.roofing_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.roofing_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.6.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.roofing_guarantee_documents_files`,
                                    fileName: `${parentPath}.roofing_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.7) Does the property benefit from central heating guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.central_heating_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.central_heating_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.7.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.central_heating_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.central_heating_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.7.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.central_heating_guarantee_documents_files`,
                                    fileName: `${parentPath}.central_heating_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.8) Does the property benefit from an underpinning guarantee?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.underpinning_guarantee`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.underpinning_guarantee === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.8.1) Please confirm whether you hold a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.underpinning_guarantee_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.underpinning_guarantee_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.8.1.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.underpinning_guarantee_documents_files`,
                                    fileName: `${parentPath}.underpinning_guarantee_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(5.1.9) Does the property benefit from any other guarantee(s)?', class: 'pl-8', required: true },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.other_guarantees`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.other_guarantees === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 20, class: 'pl-8' },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.9.1) Please provide details of any further works with the benefit of a guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.other_guarantees_details`,
                                    inputType: 'textarea',
                                    rows: 6,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                              {
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.9.2) Please supply a copy of the relevant guarantee(s)', required: true },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.other_guarantees_documents`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: "Can't locate", value: 'cantLocate' },
                                      { label: 'Did not obtain', value: 'didNotObtain' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.other_guarantees_documents === 'attached'`),
                                $cmp: 'el-form-item',
                                props: { label: '(5.1.9.2.1) Please upload a copy of the guarantee', required: true },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.other_guarantees_documents_files`,
                                    fileName: `${parentPath}.other_guarantees_documents_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                if: isBaspiOnly(),
                $cmp: 'ElRow',
                props: { gutter: 20 },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(5.1.10) Please confirm that you will leave all paperwork relating to any guarantee or warranty at the property when you move out',
                          class: 'pl-8',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.leave_guarantee_paperwork`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(5.1.11) To your knowledge, has the property ever been subject to subsidence or structural fault?',
                          class: 'pl-8',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.subsidence_structural_fault`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
