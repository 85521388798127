import { fullOccupierName, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(11.5) Is the property being sold with vacant possession?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_sold_with_vacant_possession`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        attrs: {
                          disabled: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes' || $formData.status === 'submitted'`),
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'no'`),
                    $cmp: 'el-form-item',
                    props: { label: '(11.5.1) Please supply a copy of the tenancy agreement', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.tenancy_agreement`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: `${isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'no'`)} && ${isTrueCondition(`${parentPath}.tenancy_agreement === 'attached'`)}`,
                    $cmp: 'el-form-item',
                    props: { label: '(11.5.1.1) Please upload the evidence', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.tenancy_agreement_files`,
                        fileName: `${parentPath}.tenancy_agreement_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: `${isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`)}`,
            $el: 'div',
            children: [
              {
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    if: `${isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`)} || ${isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`)}`,
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'formkit-label mt-4 mb-2',
                        },
                        children: [
                          {
                            $el: 'span',
                            children: '(11.5.1) ',
                          },

                          {
                            if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`),
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_leave_prior_to_completion === 'yes'`),
                                then: `$: ${fullOccupierName(parentPath, 'occupier1')} + " agreed to leave prior to completion"`,
                                else: `$: ${fullOccupierName(parentPath, 'occupier1')} + " did not agree to leave prior to completion"`,
                              },
                            ],
                          },
                          {
                            if: `${isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`)}`,
                            $el: 'span',
                            children: [
                              {
                                $el: 'span',
                                children: ',',
                              },
                              {
                                $el: 'br',
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_leave_prior_to_completion === 'yes'`),
                                then: `$: ${fullOccupierName(parentPath, 'occupier2')} + " agreed to leave prior to completion"`,
                                else: `$: ${fullOccupierName(parentPath, 'occupier2')} + " did not agree to leave prior to completion"`,
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'no'`),
                            $el: 'span',
                            attrs: {
                              class: 'text-sm font-semibold bg-gray-100 p-1 text-gray-600 italic',
                            },
                            children: 'There is no other occupier aged 17 or over other then the owner(s)',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                if: `${isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`)} && ${isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`)}`,
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'formkit-label mt-4 mb-2',
                        },
                        children: [
                          {
                            $el: 'span',
                            children: '(11.5.2) ',
                          },
                          {
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_sign_contract === 'yes'`),
                                then: `$: ${fullOccupierName(parentPath, 'occupier1')} + " agreed to sign the contract to confirm that they will vacate the property prior to completion of the sale"`,
                                else: `$: ${fullOccupierName(parentPath, 'occupier1')} + " did not agree to sign the contract to confirm that they will vacate the property prior to completion of the sale"`,
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
                            $el: 'span',
                            children: [
                              {
                                $el: 'span',
                                children: ',',
                              },
                              {
                                $el: 'br',
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_sign_contract === 'yes'`),
                                then: `$: ${fullOccupierName(parentPath, 'occupier2')} + " agreed to sign the contract to confirm that they will vacate the property prior to completion of the sale "`,
                                else: `$: ${fullOccupierName(parentPath, 'occupier2')} + " did not agree to sign the contract to confirm that they will vacate the property prior to completion of the sale "`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                if: `${isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'no'`)} && ${isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'no'`)}`,
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'formkit-label mt-4 mb-2',
                        },
                        children: [
                          {
                            $el: 'span',
                            children: '(11.5.2) There is no other occupier aged 17 or over other then the seller',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                if: `${isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`)} && ${isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`)}`,
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    if: `${isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_sign_contract === 'no'`)} || ${isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_sign_contract === 'no'`)}`,
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'formkit-label mt-4 mb-2',
                        },
                        children: [
                          {
                            $el: 'span',
                            children: '(11.5.2.1) ',
                          },
                          {
                            if: `${isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_sign_contract === 'no'`)}`,
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier1.provide_additional_evidence === 'attached'`),
                                then: `$: "Additional evidence to show the property will be vacant on the completion date for " + ${fullOccupierName(parentPath, 'occupier1')} + " is attached"`,
                                else: `$: "Additional evidence to show the property will be vacant on the completion date for " + ${fullOccupierName(parentPath, 'occupier1')} + " is to follow"`,
                              },
                            ],
                          },
                          {
                            if: `${isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`)} && ${isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_sign_contract === 'no'`)} && ${isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_sign_contract === 'no'`)}`,
                            $el: 'span',
                            children: [
                              {
                                $el: 'span',
                                children: ',',
                              },
                              {
                                $el: 'br',
                              },
                            ],
                          },
                          {
                            if: `${isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`)} &&  ${isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_sign_contract === 'no'`)}`,
                            $el: 'span',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.occupier2.provide_additional_evidence === 'attached'`),
                                then: `$: "Additional evidence to show the property will be vacant on the completion date for " + ${fullOccupierName(parentPath, 'occupier2')} + " is attached"`,
                                else: `$: "Additional evidence to show the property will be vacant on the completion date for " + ${fullOccupierName(parentPath, 'occupier2')} + " is to follow"`,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
