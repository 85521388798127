export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(8.1) Have you received notice of complaint from your landlord and/ or management company and/or your neighbour in your building?',
            class: 'mt-4',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'complaints.have_you_received_notice_of_complaint_from_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.complaints.have_you_received_notice_of_complaint_from_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building === "Yes"`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'complaints.provide_details_of_the_notice_of_complaint_from_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(8.2) Have you made a complaint against your landlord and/ or management company and/or your neighbour in your building?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'complaints.have_you_made_a_complaint_against_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.complaints.have_you_made_a_complaint_against_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building === "Yes"`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'complaints.provide_details_of_the_complaint_against_your_landlord_and_or_management_company_and_or_your_neighbour_in_your_building',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(8.3) Are there any on-going forfeiture proceedings relating to the property?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'complaints.any_on_going_forfeiture_proceedings_relating_to_the_property',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.complaints.any_on_going_forfeiture_proceedings_relating_to_the_property === "Yes"`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'complaints.provide_details_of_any_on_going_forfeiture_proceedings_relating_to_the_property',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(8.4) Are there any unresolved disputes with any of the Lessees in the building?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'complaints.are_there_any_unresolved_disputes_with_any_of_the_Lessees_in_the_building',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.complaints.are_there_any_unresolved_disputes_with_any_of_the_Lessees_in_the_building === "Yes"`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'complaints.provide_details_of_any_unresolved_disputes_with_any_of_the_Lessees_in_the_building',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(8.5) Do you owe any service charges, ground rent, building insurance premium or other financial payment?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'complaints.do_you_owe_any_service_charges_ground_rent_building_insurance_premium_or_other_financial_payment',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.complaints.do_you_owe_any_service_charges_ground_rent_building_insurance_premium_or_other_financial_payment === "Yes"`,
          props: {
            label:
              'Please provide details of: How much you owe? Why? Do you intend to pay it? By when? Any action notified to be taken against you for arrears?',
            class: 'section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'complaints.provide_details_of_how_much_you_owe_why_do_you_intend_to_pay_it_by_when_any_action_notified_to_be_taken_against_you_for_arrears',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
