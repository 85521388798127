export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(8.6) Please kindly provide details of any other rights/arrangements that you are aware of?', required: true },
            children: [
              {
                $formkit: 'el-input',
                inputType: 'textarea',
                name: `${parentPath}.other_rights_or_arrangements`,
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
