import LeaseholdInfo from '~/components/LeaseholdInfo.vue'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: '(1.1) How do you own your lease?', class: 'mt-4', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.ownership_type',
              options: ['Leasehold flat', 'Leasehold house', 'Share of Freehold', 'Shared ownership'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.your_leasehold_property.ownership_type === "Shared ownership"`,
          props: { label: '(1.1.1) The share percentage being sold?', required: true },
          children: [
            {
              $formkit: 'el-input-percentage',
              name: 'your_leasehold_property.share_percentage',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
              min: 1,
              max: 100,
              class: 'w-1/3',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.your_leasehold_property.ownership_type === "Shared ownership" && $formData.is_baspi_visible`,
          props: { label: '(1.1.2) The monthly rent for the remaining share', required: true },
          children: [
            {
              $formkit: 'el-input-money',
              name: 'your_leasehold_property.monthly_rent',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
              class: 'w-1/3',
            },
          ],
        },
        {
          $cmp: 'el-form-item',
          if: `$formData.is_baspi_visible`,
          props: {
            label:
              '(1.1.3) What is the minimum tenancy length (i.e., In UK shared ownership, "minimum tenancy length" is the shortest time you must stay in the property before you can sell or move out. This period varies but is typically from 6 months to several years, as specified in your shared ownership agreement)?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input-number',
              name: 'your_leasehold_property.min_tenancy_length',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
              controlsPosition: 'right',
              class: 'w-1/3',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: {
            label:
              '(1.1.4) Security deposit required for the remaining share (i.e., "security deposit required for the remaining share" means you need to provide a deposit as security for the part of the property you don\'t own and are renting, usually from a housing association. This deposit acts as a safeguard against potential damages or unpaid rent. The size of the deposit and terms vary based on the agreement).',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input-money',
              name: 'your_leasehold_property.security_deposit',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
              class: 'w-1/3',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: { label: '(1.1.5) Are you aware of any additional liabilities or obligations? (e.g., resale fees)', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.additional_liabilities',
              id: 'your_leasehold_propertyadditional_liabilities',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.your_leasehold_property.additional_liabilities === "Yes" && $formData.is_baspi_visible`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'your_leasehold_property.liabilities_details',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.is_baspi_visible`,
          props: { label: '(1.1.6) The frequency of paymeny of the shared ownership rent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'your_leasehold_property.frequency_of_payment_of_shared_ownership_rent',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'h1',
          children: 'Ground Rent',
          attrs: {
            class: 'text-large mb-4',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(1.2) Do you pay ground rent?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.pay_ground_rent',
              id: 'your_leasehold_propertypay_ground_rent',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: { label: '(1.2.1) What is the annual ground rent £ per year?', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.annual_ground_rent',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: { label: '(1.2.2) Is the rent subject to change?', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.rent_subject_to_change',
              id: 'your_leasehold_propertyrent_subject_to_change',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.rent_subject_to_change == "Yes" && $formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: { label: '(1.2.2.1) How often is the rent reviewed?', class: 'sub-section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.how_often_rent_reviewed',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.rent_subject_to_change == "Yes" && $formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: {
            label: '(1.2.2.2) How is the rent increase calculated (e.g. doubles, in line with retail price index etc)?',
            class: 'sub-section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.how_rent_increase_calculated',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: { label: '(1.2.3) When is rent paid?', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.when_rent_paid',
              id: 'your_leasehold_propertywhen_rent_paid',
              options: ['Yearly', 'Quarterly', 'Every 6 months', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: {
            label: '(1.2.4) When was rent last paid, which period did ground rent cover? State not known if not known.',
            class: 'section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.when_rent_last_paid',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: {
            label: '(1.2.5) What is date of next ground rent due, and which period will it cover? State not known if not known.',
            class: 'section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.what_date_next_ground_rent_due',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.your_leasehold_property.pay_ground_rent == "Yes"',
          props: { label: '(1.2.6) To whom should ground rent and service charges be paid?', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'your_leasehold_property.to_whom_ground_rent_paid',
              options: ['Landlord', 'Management Company', 'Managing Agent', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(1.3) How many properties are there in the building where your property is?', required: true },
          children: [
            {
              $formkit: 'el-input',
              name: 'your_leasehold_property.how_many_properties_in_building',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'div',
          if: `$saleReadyPack.uuid`,
          children: [
            {
              $cmp: LeaseholdInfo,
              props: useProps(),
            },
          ],
        },
      ],
    },
  ]
}
