import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: 'Energy Performance Certificate (EPC)',
            attrs: {
              class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
            },
          },
          {
            $cmp: 'el-form-item',
            props: { label: '(7.6) Please provide a copy of the property’s Energy Performance Certificate.', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.epc_documents`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: "Can't locate", value: 'cantLocate' },
                  { label: 'To Follow', value: 'toFollow' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.epc_documents === 'attached'`),
            $cmp: 'el-form-item',
            props: { label: '(7.6.1) Please upload a copy of the EPC', required: true },
            children: [
              {
                $formkit: 'file-upload',
                name: `${parentPath}.epc_documents_files`,
                fileName: `${parentPath}.epc_documents_files`,
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
