import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(3.2) Are you aware of any proposals to develop or alter any property, land or buildings near to the property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.proposals_to_develop_or_alter_property`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.proposals_to_develop_or_alter_property === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(3.2.1) Please give details of the proposals that you are referring to?', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-input',
                inputType: 'textarea',
                name: `${parentPath}.proposals_to_develop_or_alter_property_details`,
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
