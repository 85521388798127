import { isBaspiOnly, isFalseCondition, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(13.2) Gas Provider',
              class: 'mb-0',
              required: true,
            },
            children: [
              {
                if: `$formData.data.${parentPath}.gas_provider_not_connected_checkbox !== true && $formData.data.${parentPath}.gas_provider_other_checkbox !== true`,
                $formkit: 'select-autocomplete',
                name: `${parentPath}.gas_provider`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  'British Gas',
                  'EDF Energy',
                  'Scottish Power',
                  'Eon',
                  'Octopus Energy',
                  'O v o Energy',
                  'SSE',
                  'Utility Warehouse',
                  'Npower',
                  'Axis Energy',
                  'Good Energy',
                  'Shell Energy',
                  'E Energy',
                  'ENGIE',
                  'Green energy UK',
                  'So Energy',
                  'Utilita',
                  'Avro Energy',
                  'Breeze Energy',
                  'Ecotricity',
                  'Hub energy',
                  'Opus Energy',
                  'Outfox the Market',
                ],
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'flex gap-4 w-1/2' },
            children: [
              {
                if: isFalseCondition(`${parentPath}.gas_provider_other_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.gas_provider_not_connected_checkbox`,
                text: 'Not connected',
              },
              {
                if: isFalseCondition(`${parentPath}.gas_provider_not_connected_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.gas_provider_other_checkbox`,
                text: 'Other',
              },
            ],
          },
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                if: isTrueCondition(`${parentPath}.gas_provider_other_checkbox`),
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(13.2.1) Please provide the name of your supplier',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.gas_provider_name`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                if: isTrueCondition(`${parentPath}.gas_provider_not_connected_checkbox`),
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(13.2.1) Please clarify why the property is not connected and the date when this was disconnected or the date that this will be connected?',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.gas_provider_not_connected`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                if: `${isTrueCondition(`${parentPath}.gas_provider`)} && ${isFalseCondition(`${parentPath}.gas_provider_not_connected_checkbox`)}`,
                $cmp: 'ElRow',
                props: {
                  gutter: 20,
                },
                children: [
                  {
                    $cmp: 'ElCol',
                    props: { span: 24 },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(13.2.1) Please describe the location of the Gas Meter',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.gas_provider_meter_location`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            if: isBaspiOnly(),
            $el: 'div',
            children: [
              {
                if: `${isTrueCondition(`${parentPath}.gas_provider`)} && ${isFalseCondition(`${parentPath}.gas_provider_not_connected_checkbox`)}`,
                $cmp: 'el-form-item',
                props: {
                  label: '(13.2.2) Please provide a photograph of the location of any gas meter',
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.gas_provider_meter_location_photo`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    options: [
                      { label: 'Attached', value: 'attached' },
                      { label: 'To Follow', value: 'toFollow' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.gas_provider_meter_location_photo === 'attached'`),
                $cmp: 'el-form-item',
                props: { label: '(13.2.2.1) Please upload the photo', class: 'pl-6', required: true },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.gas_provider_meter_location_photo_files`,
                    fileName: `${parentPath}.gas_provider_meter_location_photo_files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
