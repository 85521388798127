import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isBaspiOnly(),
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(4.10) Are there any smart home systems at the property', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.smart_home_systems`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.smart_home_systems === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.10.1) Please tick those which are included in the sale' },
                    children: [
                      {
                        $formkit: 'el-checkbox-group',
                        name: `${parentPath}.smart_home_systems_included`,
                        options: [
                          { label: 'Heating and power', value: 'heating_and_power' },
                          { label: 'Security', value: 'security' },
                          { label: 'Entertainment', value: 'entertainment' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.10.2) For each system provide the name of the service and name of system', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.smart_home_systems_details`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.10.3) Confirm the app or web address, passcode and link to user instructions will be handed over on the day of completion',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.smart_home_systems_handover`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(4.10.4) Please confirm that on completion you will provide the buyer with the login, password and link to user instructions for the relevant system (NOTE It is recommended that you reset the password before handing it over to avoid security issues by passing on your own',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.smart_home_systems_login`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.10.5) Do you have a digital property logbook?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.digital_property_logbook`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.digital_property_logbook === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.10.5.1) Please confirm that on completion you will provide the buyer with the login and password to the Property Logbook',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.digital_property_logbook_login`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.10.5.2) Please confirm the name of the existing logbook provider', required: true },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.digital_property_logbook_provider`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
