export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.1) Who arranges building insurance?', class: 'mt-4', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.who_arranges_building_insurance',
              options: ['The Landlord', 'The Management Company', 'The Seller', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.2) What year were last outside, exterior, and outside communal works carried out box', required: true },
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.what_year_last_outside_exterior_and_outside_communal_works_carried_out',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.3) What year were last internal communal parts decorated or other works carried out box', required: true },
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.what_year_last_internal_communal_parts_decorated_or_other_works_carried_out',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4) Do you pay service charges, i.e. contribute to the cost of maintaining the building?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.do_you_pay_service_charges',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4.1) Do you pay service charges in', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.do_you_pay_service_charges === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.do_you_pay_service_charges_in',
              options: {
                Arrears: 'Arrears (i.e. you have not yet paid for service charges for a previous period.)',
                Advance: 'Advance (i.e. you have already paid for a period in advance, i.e. to a future date.)',
                NotKnown: 'Not Known',
              },
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
              class: 'flex flex-col place-items-start	',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4.1.1) What date did you last make payment?', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Arrears" || $formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Advance"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.what_date_last_make_payment',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4.1.2) Which period did that payment cover (i.e. what dates did the payment cover)?', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Arrears" || $formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Advance"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.which_period_did_payment_cover',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4.1.3) What is the amount of annual service charge contribution', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Arrears" || $formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Advance"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.amount_of_annual_service_charge_contribution',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.4.1.4) What is the Service charge period (e.g., every 6 months or annually)?', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Arrears" || $formData.data.maintenance_service_charges.do_you_pay_service_charges_in === "Advance"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.service_charge_period',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(5.5) Major or other future works: Do you know of any anticipated increase in future service charges (e.g over the next 3 years) for future works (including ‘major works’) which are likely to be spent and will require a contribution from you as the Lessee?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.major_or_other_future_works',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: 'Please provide detail of anticipated increases in service charges over the next 3 years (i.e. for future works or major future works)',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.maintenance_service_charges.major_or_other_future_works === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.provide_detail_of_anticipated_increases_in_service_charges',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Have you received notice of any large expense in addition to the annual payment', class: 'section-indent', required: true },
          if: `$formData.data.maintenance_service_charges.major_or_other_future_works === "Yes" && $formData.is_baspi_visible`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.received_notice_of_any_large_expense_in_addition_to_annual_payment',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.maintenance_service_charges.major_or_other_future_works === "Yes" && $formData.data.maintenance_service_charges.received_notice_of_any_large_expense_in_addition_to_annual_payment === "Yes" && $formData.is_baspi_visible`,
          props: {
            label: 'Please provide details and confirm whether there is a reserve fund or sinking fund which will cover the cost of these works',
            class: 'section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'maintenance_service_charges.provide_details_and_confirm_whether_there_is_a_reserve_fund_or_sinking_fund',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },

        {
          $cmp: 'ElFormItem',
          props: {
            label: '(5.6) Do you know of any issues in the last three years in relation to the level of service charge or with the management?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.know_of_any_issues_in_last_three_years',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.know_of_any_issues_in_last_three_years === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'maintenance_service_charges.provide_details_of_any_issues_in_last_three_years',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(5.7) Do you know of any disputes or challenge over last 3 years regarding service charges and other expenses, and its collection with the landlord and/ or management company and/ or managing agent?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.know_of_any_disputes_or_challenge_over_last_three_years',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.know_of_any_disputes_or_challenge_over_last_three_years === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.provide_details_of_any_disputes_or_challenge_over_last_three_years',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(5.8) Are you aware of or have you received any information in relation to cladding or suspected cladding used within the building or any other issues that create a building safety risk?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.are_you_aware_of_or_have_you_received_any_information_in_relation_to_cladding',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide full details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.are_you_aware_of_or_have_you_received_any_information_in_relation_to_cladding === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'maintenance_service_charges.provide_details_of_any_information_in_relation_to_cladding',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: '$formData.data.maintenance_service_charges.are_you_aware_of_or_have_you_received_any_information_in_relation_to_cladding === "Yes"',
          props: { class: 'section-indent', label: 'Please provide documents related (if applicable)', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: 'maintenance_service_charges.provide_documents_related_files',
              fileName: `maintenance_service_charges.provide_documents_related_files`,
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(5.9) Do you know of any problems in collecting ground rent and, or service charges etc from you, or from other flat leaseholders within the building (e.g. with the landlord and/ or management company and/ or managing agent?)',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.do_you_know_of_any_problems_in_collecting_ground_rent_and_or_service_charges',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.do_you_know_of_any_problems_in_collecting_ground_rent_and_or_service_charges === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'maintenance_service_charges.provide_details_problem_in_collecting_ground_rent_and_or_service_charges',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.10) Do you owe any service charges, ground rents or building insurance payments?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.do_you_owe_any_service_charges_ground_rents_or_building_insurance_payments',
              options: ['Yes', 'No', 'Not Known'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.do_you_owe_any_service_charges_ground_rents_or_building_insurance_payments === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'maintenance_service_charges.provide_details_owe_service_charges_ground_rents_or_building_insurance_payments',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(5.11) Are there any outstanding service charge consultation procedures?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.are_there_any_outstanding_service_charge_consultation_procedures',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.are_there_any_outstanding_service_charge_consultation_procedures === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.provide_details_outstanding_service_charge_consultation_procedures',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(5.12) Reserve fund: If there is a reserve fund (e.g. where you pay an amount into a reserve fund to be used against future works by the landlord and/ or management company)',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'maintenance_service_charges.reserve_fund',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please state the amount currently held in the reserve fund', class: 'section-indent', required: true },
          if: '$formData.data.maintenance_service_charges.reserve_fund === "Yes"',
          children: [
            {
              $formkit: 'el-input',
              name: 'maintenance_service_charges.state_amount_currently_held_in_reserve_fund',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
