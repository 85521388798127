import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-sm italic text-gray-500 mb-6',
                    },
                    children: [
                      {
                        $el: 'div',
                        children: 'Radon',

                        attrs: {
                          class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                        },
                      },
                      {
                        $el: 'span',
                        children: 'Note:',
                        attrs: {
                          class: 'font-bold',
                        },
                      },
                      {
                        $el: 'span',
                        children: ' Some properties suffer from a naturally occurring radio-active gas called Radon. To find out more visit: ',
                      },
                      {
                        $el: 'a',
                        children: 'www.gov.uk/government/organisations/public-health-england',
                        attrs: {
                          href: 'http://www.gov.uk/government/organisations/public-health-england',
                          target: '_blank',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(7.4) To your knowledge, has a Radon test been carried out at the property?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.radon_test`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.radon_test === 'yes'`),
                    $cmp: 'el-form-item',
                    props: { label: '(7.4.1) Please provide a copy of the Radon test report.', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.radon_test_documents`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.radon_test_documents === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(7.4.1.1) Please upload a copy of the radon test report', class: 'pl-6', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.radon_test_documents_files`,
                        fileName: `${parentPath}.radon_test_documents_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.radon_test === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: '(7.4.2) Please state whether any remedial action was advised (e.g. if test showed above recommended action level',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.radon_test_remedial_action`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
