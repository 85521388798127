import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(15.4) Please confirm if the material used to build the property is of standard construction (e.g. Block/brick/stone with tiled/slate roof) – you may be able to find this information from paperwork received when you bought the property such as mortgage valuation, private survey report, insurance documents. Note: if you have made alterations to the property since your purchase, your surveyor, builder or architect may be able to assist.',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.standard_construction`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.standard_construction === 'no'`),
            $el: 'div',
            attrs: {
              class: 'pl-8',
            },
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label:
                    '(15.4.1) If the property is non-standard construction or partial non-standard construction; then Please confirm the type of material used for construction. This is a non-exhaustive list:',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-checkbox-group',
                    name: `${parentPath}.non_standard_construction`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                    className: 'flex flex-col',
                    options: [
                      { label: 'Steel frame', value: 'steel_frame' },
                      { label: 'Timber frame', value: 'timber_frame' },
                      { label: 'Eco homes', value: 'eco_homes' },
                      { label: 'Wattle and Daub', value: 'wattle_and_daub' },
                      { label: 'Shingle roofs', value: 'shingle_roofs' },
                      { label: 'Flint', value: 'flint' },
                      { label: 'Straw bale', value: 'straw_bale' },
                      { label: 'Cob', value: 'cob' },
                      { label: 'Grass and peat walls', value: 'grass_and_peat_walls' },
                      { label: 'cladding', value: 'cladding' },
                      { label: 'HUF House', value: 'huf_house' },
                      { label: 'Glass', value: 'glass' },
                      { label: 'Corrugated Iron', value: 'corrugated_iron' },
                      { label: 'Plastic', value: 'plastic' },
                      { label: 'Fibreglass', value: 'fibreglass' },
                      { label: 'Asbestos', value: 'asbestos' },
                      { label: 'Asphalt', value: 'asphalt' },
                      { label: 'Passive houses and zero energy buildings', value: 'passive_houses_and_zero_energy_buildings' },
                      { label: 'Rubber', value: 'rubber' },
                      { label: 'Converted buildings windmills and barns', value: 'converted_buildings_windmills_and_barns' },
                      { label: 'Flat roof', value: 'flat_roof' },
                      { label: 'Thatched roof', value: 'thatched_roof' },
                      { label: 'Prefabricated concrete/modular', value: 'prefabricated_concrete_modular' },
                      { label: 'Flood area', value: 'flood_area' },
                      { label: 'Listed building', value: 'listed_building' },
                      { label: 'Suffered subsidence', value: 'suffered_subsidence' },
                      { label: 'Other: please state', value: 'other' },
                    ],
                  },
                ],
              },
              {
                if: `${isTrueCondition(`${parentPath}.non_standard_construction`)} && ${isTrueCondition(`${parentPath}.non_standard_construction.includes('other')`)}`,
                $cmp: 'el-form-item',
                props: {
                  label: "(15.4.1.1) If other, please provide description, if you don't know, please state not known",
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.non_standard_construction_other`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
