import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'text-sm',
            },
            children: [
              {
                $el: 'div',
                children: '(8.5) Do you know if any of the following rights or arrangements affect the property?',
                attrs: {
                  class: 'formkit-label mt-4 mb-2 required-label',
                },
              },
              {
                $el: 'div',
                attrs: {
                  class: 'text-gray-500 italic pl-6',
                },
                children: [
                  {
                    $el: 'div',
                    children: 'a) Other people’s rights to mines and minerals on the property',
                    attrs: {
                      class: ' text-gray-500 italic ',
                    },
                  },
                  {
                    $el: 'div',
                    children: 'b) Chancel repair liability',
                    attrs: {
                      class: 'text-gray-500 italic',
                    },
                  },
                  {
                    $el: 'div',
                    children: 'c) Other people’s rights to take things from the land (such as vegetation, wood etc.)',
                    attrs: {
                      class: 'text-gray-500 italic',
                    },
                  },
                  {
                    $el: 'div',
                    children: 'd) Please provide details',
                    attrs: {
                      class: 'text-gray-500 italic',
                    },
                  },
                  {
                    if: isBaspiOnly(),
                    $el: 'div',
                    children: [
                      {
                        $el: 'div',
                        children: 'e) A public right of way through and/or across your property, buildings or land',
                        attrs: {
                          class: 'text-gray-500 italic',
                        },
                      },
                      {
                        $el: 'div',
                        children: 'f) Please mark and upload the route on a plan of the property',
                        attrs: {
                          class: 'text-gray-500 italic',
                        },
                      },
                      {
                        $el: 'div',
                        children:
                          'g) Rights created through custom or use on the property (eg rights to graze on other land or forage, rights of the lord of the manor or others to take things from your property etc)',
                        attrs: {
                          class: 'text-gray-500 italic',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            attrs: {
              class: 'pl-8',
            },
            props: { required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                props: {
                  label: '(8.5) Do you know if any of the following rights or arrangements affect the property?',
                  required: true,
                },
                name: `${parentPath}.know_of_property_rights_or_arrangements_affecting_property`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.know_of_property_rights_or_arrangements_affecting_property === 'yes'`),
            $cmp: 'ElRow',
            props: {
              gutter: 20,
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(8.5.1) Other people’s rights to mines and minerals on the property',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        inputType: 'textarea',
                        name: `${parentPath}.other_peoples_rights_to_mines_and_minerals`,
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(8.5.2) Chancel repair liability', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        inputType: 'textarea',
                        name: `${parentPath}.chancel_repair_liability`,
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(8.5.3) Other people’s rights to take things from the land (such as vegetation, wood etc.)',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        inputType: 'textarea',
                        name: `${parentPath}.other_peoples_rights_to_take_things_from_land`,
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(8.5.4) Please provide details', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        inputType: 'textarea',
                        name: `${parentPath}.other_peoples_rights_to_take_things_from_land_details`,
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: isBaspiOnly(),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(8.5.5) A public right of way through and/or across your property, buildings or land',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            inputType: 'textarea',
                            name: `${parentPath}.public_right_of_way`,
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(8.5.6) Please mark and upload the route on a plan of the property',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.public_right_of_way_plan_files`,
                            fileName: `${parentPath}.public_right_of_way_plan_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(8.5.7) Rights created through custom or use on the property (eg rights to graze on other land or forage, rights of the lord of the manor or others to take things from your property etc)',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            inputType: 'textarea',
                            name: `${parentPath}.custom_or_use_rights`,
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
