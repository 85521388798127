import GeneralHeader from '~/components/GeneralHeader.vue'
import RadioForm from '~/components/RadioForm.vue'
import Repeater from '~/components/Repeater.vue'
import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import { isStandalone } from '~/schemas/components/common/helpers.js'

export const headings = [
  { title: '#', prop: 'title', cols: 'col-span-2 hidden md:inline' },
  { title: 'Included In Sale', prop: 'included_in_sale', cols: 'col-span-2  hidden md:inline' },
  { title: 'Excluded From Sale/Or None At Property', prop: 'excluded_from_sale', cols: 'col-span-3  hidden md:inline' },
  { title: 'Price If To Be Sold Separately', prop: 'price', cols: 'col-span-2  hidden md:inline' },
  { title: 'Add Any Comments', prop: 'comments', cols: 'col-span-2  hidden md:inline' },
  { title: '', prop: 'actions', cols: 'col-span-1  hidden md:inline' },
]

export default useSchema({
  id: 'fif',
  title: 'Fixture and Fittings Form',
  steps: true,
  autosave: true,
  signature: true,
  properties: [
    {
      $cmp: GeneralHeader,
      props: useProps({ title: 'General Information', visible: () => !isStandalone() }),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1', title: 'General Fittings' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'general_fittings',
            infoButtonText: 'Do you have any other general fittings (e.g door chime etc.) you want to add?',
            items: [
              { title: 'Boiler/immersion heater', prop: 'boiler_immersion_heater' },
              { title: 'Radiators/wall heaters', prop: 'radiators_wall_heaters' },
              { title: 'Night-storage radiators', prop: 'night_storage_radiators' },
              { title: 'Individual free-standing heaters', prop: 'individual_free_standing_heaters' },
              { title: 'Gas fires (with surround)', prop: 'gas_fires_with_surround' },
              { title: 'Electric fires (with surround)', prop: 'electric_fires_with_surround' },
              { title: 'Light Switches', prop: 'light_switches' },
              { title: 'Roof insulation', prop: 'roof_insulation' },
              { title: 'Window fittings', prop: 'window_fittings' },
              { title: 'Window shutters/grilles', prop: 'window_shutters_grilles' },
              { title: 'Internal door fittings', prop: 'internal_door_fittings' },
              { title: 'External door fittings', prop: 'external_door_fittings' },
              { title: 'Doorbell', prop: 'doorbell' },
              { title: 'Electric sockets', prop: 'electric_sockets' },
              { title: 'Burglar alarm', prop: 'burglar_alarm' },
              { title: 'Radio Aerial', prop: 'radio_aerial' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '2', title: 'Kitchen' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'kitchen',
            infoButtonText: 'Do you have any other kitchen fixtures you want to add?',
            items: [
              { title: 'Fitted Hob', prop: 'fitted_hob' },
              { title: 'Extractor hood', prop: 'extractor_hood' },
              { title: 'Cooker', prop: 'cooker' },
              { title: 'Fitted microwave', prop: 'fitted_microwave' },
              { title: 'Fridge/fridge freezer', prop: 'fridge_fridge_freezer' },
              { title: 'Freezer', prop: 'freezer' },
              { title: 'Dishwasher', prop: 'dishwasher' },
              { title: 'Tumble-dryer', prop: 'tumble_dryer' },
              { title: 'Washing Machine', prop: 'washing_machine' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '3', title: 'Bathroom' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'bathroom',
            infoButtonText: 'Do you have any other bathroom fixtures you want to add?',
            items: [
              { title: 'Bath', prop: 'bath' },
              { title: 'Medicine cabinet', prop: 'medicine_cabinet' },
              { title: 'Separate shower and fittings', prop: 'separate_shower_and_fittings' },
              { title: 'Towel Rail', prop: 'towel_rail' },
              { title: 'Soap toothbrush holders', prop: 'soap_toothbrush_holders' },
              { title: 'Mirror', prop: 'mirror' },
              { title: 'Toilet Roll Holders', prop: 'toilet_roll_holders' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blind_shutters' },
              { title: 'Taps', prop: 'taps' },
              { title: 'Shower curtain', prop: 'shower_curtain' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '4', title: 'Living Room' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'living_room',
            infoButtonText: 'Do you have any other living room fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '5', title: 'Dining' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'dinning',
            infoButtonText: 'Do you have any other Dining room fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '6', title: 'Hall and Stairs Landing' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'hall_and_stairs_landing',
            infoButtonText: 'Do you have any other hall and stairs landing fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '7', title: 'Bedroom 1' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'bedroom_1',
            infoButtonText: 'Do you have any other bedroom fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '8', title: 'Bedroom 2' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'bedroom_2',
            infoButtonText: 'Do you have any other bedroom fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '9', title: 'Bedroom 3' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'bedroom_3',
            infoButtonText: 'Do you have any other bedroom fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '10', title: 'Bedroom 4' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'bedroom_4',
            infoButtonText: 'Do you have any other bedroom fixtures you want to add?',
            items: [
              { title: 'Carpet', prop: 'carpet' },
              { title: 'Curtain rails/poles', prop: 'curtain_rails_poles' },
              { title: 'Curtains', prop: 'curtains' },
              { title: 'Blinds', prop: 'blinds' },
              { title: 'Light fittings', prop: 'light_fittings' },
              { title: 'Fitted Cupboards, shelves, wardrobes/other units etc.', prop: 'fitted_cupboards_shelves' },
              { title: 'Window blinds/shutters/grilles (e.g. doors, windows etc)', prop: 'window_blinds_shutters' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '11', title: 'Outdoor' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'outdoor',
            infoButtonText: 'Do you have any other outdoor fixtures you want to add?',
            items: [
              { title: 'Garden shed', prop: 'garden_shed' },
              { title: 'Garden furniture', prop: 'garden_furniture' },
              { title: 'Garden ornaments', prop: 'garden_ornaments' },
              { title: 'Trees and plants', prop: 'trees_and_plants' },
              { title: 'Dustbins', prop: 'dustbins' },
              { title: 'Greenhouse', prop: 'greenhouse' },
              { title: 'Outside Lights', prop: 'outside_lights' },
              { title: 'Clothes line/Rotary dryer', prop: 'clothes_line_rotary_dryer' },
              { title: 'Outdoor Heater', prop: 'outdoor_heater' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '12', title: 'Stock of Fuel' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'stock_of_fuel',
            infoButtonText: 'Do you have any other stock fixtures you want to add?',
            items: [
              { title: 'Oil', prop: 'oil' },
              { title: 'Wood', prop: 'wood' },
              { title: 'Bottled gas', prop: 'bottled_gas' },
              { title: 'Liquefied Petroleum Gas (LPG)', prop: 'liquefied_petroleum_gas_lpg' },
            ],
          }),
        },
      ],
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '13', title: 'Any Other Fixtures and Fittings' }),
      children: [
        {
          $cmp: RadioForm,
          props: useProps({
            headings,
            prop: 'any_other_fixtures_and_fittings',
            infoButtonText: 'Any other fixtures and fittings?',
            items: [],
          }),
        },
      ],
    },
    {
      $cmp: Repeater,
      props: useProps({ title: 'Additional Rooms', prop: 'new_rooms', buttonText: 'Add new room', items: [], removeButton: true }),
      children: [
        {
          $cmp: SectionCard,
          props: useProps({ tab: '$: $index + 14', index: '$: $index', class: 'mb-6', title: '', titleProp: 'title', removeButton: true }),
          children: [
            {
              $cmp: RadioForm,
              props: useProps({
                headings,
                index: '$: $index',
                parentProp: '$: "new_rooms." + $index',
                items: [],
              }),
            },
          ],
        },
      ],
    },
  ],
})
