export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(7.1) Have you received Notice from the Landlord or Management Company of any changes to the terms of the lease/consents under the lease or any other notices.',
            class: 'mt-4',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'consents.have_you_received_a_notice_from_the_landlord_that_they_wish_to_sell_the_property',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.consents.have_you_received_a_notice_from_the_landlord_that_they_wish_to_sell_the_property === "Yes"`,
          props: { label: '(a) Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'consents.provide_details_of_the_notice_from_the_landlord',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(b) Please provide a copy of the consent and / or evidence of the landlord’s consent.', class: 'section-indent', required: true },
          if: `$formData.data.consents.have_you_received_a_notice_from_the_landlord_that_they_wish_to_sell_the_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'consents.provide_a_copy_of_the_consent_and_or_evidence_of_the_landlords_consent',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.consents.provide_a_copy_of_the_consent_and_or_evidence_of_the_landlords_consent === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `consents.provide_a_copy_of_the_consent_and_or_evidence_of_the_landlords_consent_files`,
              fileName: `consents.provide_a_copy_of_the_consent_and_or_evidence_of_the_landlords_consent_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
