export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(9.1) Do you know of any alterations made to the property since the lease was granted? Change of use or change or physical alterations?',
            class: 'mt-4',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'alterations.any_alterations_made_to_the_property_since_the_lease_was_granted',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(a) Please provide details of all alterations if more than one, including year of alteration',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.alterations.any_alterations_made_to_the_property_since_the_lease_was_granted === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'alterations.provide_details_of_all_alterations',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },

        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(b) Please provide consent for each alteration if more than one, and if required. If this has mislaid, reply lost, and if you do not know, reply don’t know etc (e.g. internal walls or partition work carried out, carpets rather than wooden floors laid etc)',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.alterations.any_alterations_made_to_the_property_since_the_lease_was_granted === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'alterations.provide_a_copy_of_the_consent_for_each_alteration',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.alterations.provide_a_copy_of_the_consent_for_each_alteration === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `alterations.provide_a_copy_of_the_consent_for_each_alteration_files`,
              fileName: `alterations.provide_a_copy_of_the_consent_for_each_alteration_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
