import { isFalseCondition } from '~/schemas/components/common/helpers'
import inlineSwitcher from '~/schemas/components/common/inline-switcher'
import estateAgent from '~/schemas/components/instruction/estate-agent'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'pl-7 pr-6 pt-6' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'el-form-item',
                  props: { required: true },
                  children: [
                    {
                      $el: 'div',
                      children: '£ Price you have agreed on Sale.',
                      attrs: {
                        class: 'text-sm font-[500] text-black required-label',
                        required: true,
                      },
                    },
                    {
                      $el: 'div',
                      children: 'Please do not use commas when writing the price',
                      attrs: {
                        class: 'text-sm text-black ml-1',
                      },
                    },
                    {
                      $formkit: 'el-input-money',
                      name: `${parentPath}.agreed_selling_price`,
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                      class: 'max-w-[340px] mb-6',
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                ...inlineSwitcher(
                  parentPath + '.is_address_identical_to_residing_address',
                  'Is this address identical to your residing address above?',
                  'mb-0',
                ),
              ],
            },
            {
              if: isFalseCondition(parentPath + '.is_address_identical_to_residing_address'),
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: { label: 'Search Address', required: true },
                  children: [
                    {
                      $formkit: 'address-form-autocomplete',
                      name: `${parentPath}.selling_property_address`,
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: {
                    label: 'Type of the Property You are Selling',
                    class: 'font-bold',
                  },
                  children: [
                    {
                      $formkit: 'el-radio-group',
                      name: parentPath + '.property_type',
                      options: [
                        {
                          label: 'Freehold',
                          value: 'freehold',
                        },
                        {
                          label: 'Leasehold',
                          value: 'leasehold',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            // {
            //   if: isTrueCondition(`${parentPath}.property_type === "leasehold"`),
            //   $cmp: 'ElCol',
            //   props: { span: 24 },
            //   children: [
            //     {
            //       $cmp: 'ElRow',
            //       props: { gutter: 20 },
            //       children: [
            //         {
            //           $cmp: 'ElCol',
            //           props: { span: 24 },
            //           children: [
            //             {
            //               $cmp: 'ElCol',
            //               props: { span: 24 },
            //               children: [...managementCompany(parentPath)],
            //             },
            //             {
            //               $cmp: 'ElCol',
            //               props: { span: 24 },
            //               children: [...landlord(parentPath)],
            //             },
            //           ],
            //         },
            //       ],
            //     },
            //   ],
            // },

            // {
            //   $cmp: 'ElCol',
            //   props: { span: 24 },
            //   children: [...mortgageLenders(parentPath)],
            // },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...estateAgent(parentPath, 'Selling')],
            },
          ],
        },
      ],
    },
  ]
}
