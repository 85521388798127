import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-sm italic text-gray-500 mb-6',
                    },
                    children: [
                      {
                        $el: 'h3',
                        children: 'Green Deal Scheme',
                        attrs: {
                          class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                        },
                      },
                      {
                        $el: 'span',
                        children: 'Note:',
                        attrs: {
                          class: 'font-bold',
                        },
                      },
                      {
                        $el: 'span',
                        children: ' Go to the Green Deal site to find information on this, at:',
                      },
                      {
                        $el: 'a',
                        children: 'www.gov.uk/green-deal-energy-saving-measures',
                        attrs: {
                          href: 'http://www.gov.uk/green-deal-energy-saving-measures',
                          target: '_blank',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(7.7) Is this property subject to a Green Deal loan or other financed home improvement scheme? (i.e. have any installations in the property been financed under the Green Deal scheme)?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.green_deal_loan`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.green_deal_loan === 'yes'`),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(7.7.1) Please provide details below including any outstanding payments for the renewable devices and any feed-in tariffs.',
                          class: 'pl-6',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.green_deal_loan_details`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                          },
                        ],
                      },
                      {
                        if: isBaspiOnly(),
                        $el: 'div',
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(7.7.2) Please provide a copy of your last electricity bill and details of any feed in tariffs',
                              class: 'pl-6',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.electricity_bill`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.electricity_bill === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(7.7.2.1) Please upload a copy of the bill and tariff information', class: 'pl-6', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.electricity_bill_files`,
                                fileName: `${parentPath}.electricity_bill_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
