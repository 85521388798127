import { isTrueCondition } from '~/schemas/components/common/helpers'
export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: [
              {
                $el: 'span',
                children: '* ',
                attrs: {
                  class: 'text-red-400',
                },
              },
              '1.2. If the boundaries are irregular, please supply a plan or drawing indicating ownership of each part of the boundary.',
            ],
            attrs: {
              class: 'text-sm  mb-4 mt-4',
            },
          },
          {
            $cmp: 'ElFormItem',
            props: { required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.irregular_boundaries`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: "Can't locate", value: 'cantLocate' },
                  { label: 'To Follow', value: 'toFollow' },
                  { label: 'Not known', value: 'notKnown' },
                  { label: 'N/A', value: 'notApplicable' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.irregular_boundaries === 'attached'`),
            $cmp: 'ElFormItem',
            props: {
              label: '(1.2.1) Please kindly attach the plan or drawing indicating ownership of each part of the boundary',
              required: true,
            },
            children: [
              {
                $formkit: 'file-upload',
                name: `${parentPath}.irregular_boundaries_files`,
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                fileName: `boundary.irregular_boundaries_files`,
              },
            ],
          },
        ],
      },
    ],
  }
}
