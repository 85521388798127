import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(14.1) Will you need the purchase, sale or remortgage of another property to co-ordinate with the sale of this property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.purchase_sale_remortgage_co_ordinate`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isBaspiOnly() && isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label: '(14.1.1) Please select what is applicable to your situation',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.purchase_sale_remortgage_co_ordinate_applicable`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Purchase', value: 'purchase' },
                  { label: 'Further Sale', value: 'further_sale' },
                  { label: 'Remortgage', value: 'remortgage' },
                ],
              },
            ],
          },
          {
            if: `${isBaspiOnly()} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable`)} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable.includes('further_sale')`)}`,
            $cmp: 'el-form-item',
            props: {
              label: '(14.1.2) Please indicate what stage your associated sale has reached',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.associated_sale_stage`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
          {
            if: `${isBaspiOnly()} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable`)} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable.includes('purchase')`)}`,
            $cmp: 'el-form-item',
            props: {
              label: '(14.1.3) Please indicate what stage your purchase has reached.',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.purchase_stage`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
          {
            if: `${isBaspiOnly()} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable`)} && ${isTrueCondition(`${parentPath}.purchase_sale_remortgage_co_ordinate_applicable.includes('remortgage')`)}`,
            $cmp: 'el-form-item',
            props: {
              label: '(14.1.4) Please indicate what stage your remortgage has reached',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.remortgage_stage`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
