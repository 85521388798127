<script setup>
import get from 'lodash/get'
import { computed } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import { useFormStatusStore } from '~/stores/form-status.js'
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  parentIndex: {
    type: Number,
    required: false,
    default: 0,
  },
  parentProp: {
    type: String,
    required: false,
    default: '',
  },
  prop: {
    type: String,
    required: true,
  },
  isCustom: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['remove'])

const { width } = useWindowSize()

const options = computed(() => [
  { value: 'included_in_sale', label: isMobile.value ? 'Included' : 'Included in Sale' },
  { value: 'excluded_from_sale', label: isMobile.value ? 'Excluded' : 'Excluded from Sale / Or none at property' },
])

const isEditable = computed(() => useFormStatusStore().isEditable)

function isExcluded(name) {
  const prop = props.parentProp ? `${props.parentProp}.${name}` : name
  return get(props.context.formData.data, prop) === 'excluded_from_sale'
}

const isMobile = computed(() => width.value < 500)
</script>

<template>
  <div class="my-8 cursor-pointer odd:bg-gray-50 hover:bg-gray-100">
    <div class="grid w-full grid-cols-1 gap-3 py-2 sm:grid-cols-12">
      <div class="col-span-2 flex items-center px-2 text-sm tracking-wide text-black sm:col-span-2">
        <span v-if="!isCustom" class="text-lg">{{ title }}</span>
        <FormKit v-else :label="isMobile ? 'Item' : ''" type="el-input" name="title" validation="required" />
      </div>

      <div class="col-span-5 px-2 sm:col-span-5">
        <FormKit
          :name="!isCustom ? `${prop}.value` : 'value'"
          :error="isExcluded(`${prop}.value`)"
          :options="options"
          type="el-radio-group-button"
          class="w-full"
        />
      </div>

      <div class="col-span-6 px-2 md:col-span-2">
        <FormKit
          :label="isMobile ? 'Price If To Be Sold Separately' : ''"
          :name="!isCustom ? `${prop}.price_if_sold_separately` : 'price_if_sold_separately'"
          type="el-input"
          class="text-sm"
        />
      </div>

      <div class="col-span-6 px-2 md:col-span-2">
        <FormKit :label="isMobile ? 'Comments' : ''" :name="!isCustom ? `${prop}.comments` : 'comments'" type="el-input" />
      </div>

      <div class="col-span-1 px-2 sm:col-span-1">
        <el-button v-if="isCustom" :icon="Delete" :disabled="!isEditable" type="danger" circle @click="$emit('remove')" />
      </div>
    </div>
  </div>
</template>
<style scoped>
/deep/ .formkit-outer .formkit-wrapper .formkit-label {
  font-weight: 200 !important;
  font-size: 0.7rem !important;
}
</style>
