import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'
import DatePicker from '~/schemas/components/date-picker.js'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(4.6) Are there solar or photovoltaic panels installed at the property?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.solar_panels_installed`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.solar_panels_installed === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    children: [DatePicker('(4.6.1) When were they installed?', `${parentPath}.solar_panels_installation_date`, true, '!max-w-[450px]')],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.6.2) Do you own the panels, and all equipment related to them, outright? (select no if these are leased)',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.solar_panels_owned`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.solar_panels_owned === 'no'`),
                    $cmp: 'el-form-item',
                    props: { label: '(4.6.2.1) Please provide details of who owns them', required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.solar_panels_ownership_details`,
                        inputType: 'textarea',
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.6.3) Is the system connected to the National Grid eg for feed-in tariffs FiT (FiT) or Smart Export Guarantee (SEG)?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.solar_panels_connected_to_grid`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.solar_panels_connected_to_grid === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.6.3.1) Please provide copies of any relevant documents (such as electricity bills for feed in tariffs)',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.solar_panels_connected_to_grid_documents`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.solar_panels_connected_to_grid_documents === 'attached'`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.6.3.1.1) Please provide a copy of the bills and feed in tariff details',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.solar_panels_connected_to_grid_files`,
                                fileName: `${parentPath}.solar_panels_connected_to_grid_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    children: [
                      DatePicker(
                        '(4.6.4) When was the system last maintained or serviced?',
                        `${parentPath}.solar_panels_last_serviced`,
                        true,
                        '!max-w-[450px]',
                      ),
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.6.5) Is the system in good working order to your satisfaction?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.solar_panels_working_order`,
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                      },
                    ],
                  },
                  {
                    if: isBaspiOnly(),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(4.6.6) Please describe the location of any photovoltaic panel meter if applicable' },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.solar_panels_meter_location`,
                            inputType: 'textarea',
                            rows: 6,
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(4.6.7) Please provide a photo of the location of any photovoltaic panel meter if applicable',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.solar_panels_meter_photo`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            validationVisibility: 'live',
                            options: [
                              { label: 'Attached', value: 'attached' },
                              { label: 'Not applicable', value: 'notApplicable' },
                              { label: 'To Follow', value: 'toFollow' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.solar_panels_meter_photo === 'attached'`),
                        $cmp: 'el-form-item',
                        props: {
                          label: '(4.6.7.1) Please attach a photo of the location of any photovoltaic panel meter if applicable',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.solar_panels_meter_photo_files`,
                            fileName: `${parentPath}.solar_panels_meter_photo_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: { label: '(4.6.8) Please describe the location of any photovoltaic batteries if applicable' },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.solar_panels_batteries_location`,
                            inputType: 'textarea',
                            rows: 6,
                          },
                        ],
                      },
                      {
                        $el: 'div',
                        children: [
                          {
                            if: isTrueCondition(`${parentPath}.solar_panels_installed`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.6.9) Please provide a photo of the location of any photovoltaic batteries if applicable',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.solar_panels_batteries_photo`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: 'Not applicable', value: 'notApplicable' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.solar_panels_batteries_photo === 'attached'`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.6.9.1) Please provide a photo of the location of any photovoltaic batteries if applicable',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.solar_panels_batteries_photo_files`,
                                fileName: `${parentPath}.solar_panels_batteries_photo_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
