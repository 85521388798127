import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_flooded === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(7.2) What type of flooding was it?', required: true },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.property_flooded_type`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                options: [
                  { label: 'Surface Water', value: 'surface_water' },
                  { label: 'Sewer Water', value: 'sewer_water' },
                  { label: 'Ground Water', value: 'ground_water' },
                  { label: 'River', value: 'river' },
                  { label: 'Coastal', value: 'coastal' },
                  { label: 'Other', value: 'other' },
                ],
              },
            ],
          },
          {
            if: `${isTrueCondition(`${parentPath}.property_flooded_type`)} && ${isTrueCondition(`${parentPath}.property_flooded_type.includes('other')`)}`,
            $cmp: 'el-form-item',
            props: { label: '(7.2.1) Please clarify what "other" type of flooding occurred', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_flooded_type_other`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
