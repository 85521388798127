import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'
import DatePicker from '~/schemas/components/date-picker.js'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'formkit-label mt-4',
            },

            children: [
              {
                $el: 'span',
                children: '* ',
                attrs: {
                  class: 'text-red-400',
                },
              },
              {
                $el: 'span',
                children: '(4.1) Have any alterations taken place to the property (completed or unfinished) e.g. such as:',
              },
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'text-sm pl-8',
            },
            children: [
              {
                $el: 'div',
                children: 'a) Building works (such as adding a loft, an extension, removing internal walls or converting a garage).',
              },
              {
                $el: 'div',
                children: 'b) The installation or replacement of windows, glazed doors or roof windows from 1st of April 2002',
              },
              {
                $el: 'div',
                children: 'c) Adding a conservatory to the property',
              },
              {
                $el: 'div',
                children: 'd) Change of use i.e. such change of use from the property as an office to the property as a residence?',
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            props: { required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.alterations_taken_place`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                validationVisibility: 'live',
                attrs: {
                  class: 'mt-4',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },

          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },

            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                    },
                    children: 'List out alterations made to the property, from the following:',
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.1.1) Building works (e.g. loft, extension, removing internal walls or converting a garage).', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.building_works_taken_place`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.1.1.1) Please indicate which alteration you have made, and confirm if you will attach planning permission, Building Regulations and Completion certificate for each change.',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-checkbox-group',
                                name: `${parentPath}.building_works_description`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                className: 'flex flex-col',
                                options: [
                                  { label: 'Loft', value: 'loft' },
                                  { label: 'Extension', value: 'extension' },
                                  { label: 'Removing internal walls', value: 'removing_internal_walls' },
                                  { label: 'Converting a garage', value: 'converting_garage' },
                                  { label: 'Other', value: 'other' },
                                ],
                              },
                            ],
                          },
                          {
                            if: `${isTrueCondition(`${parentPath}.building_works_description`)} && ${isTrueCondition(`${parentPath}.building_works_description.includes('other')`)}`,
                            $cmp: 'el-form-item',
                            props: { label: 'Please clarify what "other" type of work', required: true },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.building_works_description_other`,
                                inputType: 'textarea',
                                rows: 6,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.1.2) Were these changes made during your ownership', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.building_works_during_ownership`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.building_works_during_ownership === 'yes'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            attrs: {
                              class: 'pl-8',
                            },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.1.2.1) Do you remember the date you made this change?', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.building_works_date_known`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    $cmp: 'el-form-item',
                                    if: isTrueCondition(`${parentPath}.building_works_date_known === 'yes'`),
                                    children: [
                                      DatePicker('(4.1.1.2.1.1) What is the date of alteration?', `${parentPath}.building_works_date`, true, '!max-w-[450px]'),
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.1.3) Supply Building Regulations and Completion certificate for building works', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.building_works_certificate`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.building_works_certificate === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.1.3.1) Please provide a copy of the certificate(s)', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.building_works_certificate_files`,
                                fileName: `${parentPath}.building_works_certificate_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.1.1.4) Supply Planning permission if required (i.e. unless exempt under permitted development) for each alteration',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.building_works_permission`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                  { label: 'N/A', value: 'notApplicable' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.building_works_permission === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.1.4.1) Please provide a copy of the permission(s)', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.building_works_permission_files`,
                                fileName: `${parentPath}.building_works_permission_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $el: 'div',
                            attrs: {
                              class: 'formkit-label mb-2',
                            },
                            children: [
                              {
                                $el: 'span',
                                children: '* ',
                                attrs: {
                                  class: 'text-red-400',
                                },
                              },
                              '(4.1.1.6)  Please set out details in box below for each alteration, whether or not it was carried out by you:',
                            ],
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: 'text-sm pl-8',
                            },
                            children: [
                              {
                                $el: 'div',
                                children: 'i). Breach of planning permission, Building Regulations consent conditions if any,',
                              },
                              {
                                $el: 'div',
                                children: 'ii). Work which that has not met the Building Regulation consent conditions, if any,',
                              },
                              {
                                $el: 'div',
                                children: 'iii). Any planning or Building Regulation control issues that need to be resolved.',
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: { required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.building_works_breach`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.building_works_taken_place === 'yes'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.building_works_breach === 'yes'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label: 'Please select the relevant issues',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-checkbox-group',
                                    name: `${parentPath}.building_works_breach_select`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    validationVisibility: 'live',
                                    options: [
                                      {
                                        label: 'Breach of planning permission, Building Regulations consent conditions if any',
                                        value: 'planning_permission_breach',
                                      },
                                      {
                                        label: 'Work which that has not met the Building Regulation consent conditions, if any',
                                        value: 'building_regulation_not_met',
                                      },
                                      {
                                        label: 'Any planning or Building Regulation control issues that need to be resolved',
                                        value: 'building_regulation_control_issues',
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    if: `${isTrueCondition(`${parentPath}.building_works_breach_select`)} && ${isTrueCondition(`${parentPath}.building_works_breach_select.includes('planning_permission_breach')`)}`,
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.1.6.1.a) Please give details of the planning permission breach', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.planning_permission_breach_details`,
                                        inputType: 'textarea',
                                        rows: 6,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                  {
                                    if: `${isTrueCondition(`${parentPath}.building_works_breach_select`)} && ${isTrueCondition(`${parentPath}.building_works_breach_select.includes('building_regulation_not_met')`)}`,
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.1.6.1.b) Please give details of the building regulations not met', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.building_regulation_not_met_details`,
                                        inputType: 'textarea',
                                        rows: 6,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                  {
                                    if: `${isTrueCondition(`${parentPath}.building_works_breach_select`)} && ${isTrueCondition(`${parentPath}.building_works_breach_select.includes('building_regulation_control_issues')`)}`,
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: '(4.1.1.6.1.c) Please give details of any planning or Building Regulation control issues',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.building_regulation_control_issues_details`,
                                        inputType: 'textarea',
                                        rows: 6,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.building_works_breach === 'yes'`),
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: '(4.1.1.6.2) Please provide a copy of any supporting correspondence',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.building_works_breach_provide_copy`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Attached', value: 'attached' },
                                          { label: "Can't locate", value: 'cantLocate' },
                                          { label: 'Did not obtain', value: 'didNotObtain' },
                                          { label: 'To Follow', value: 'toFollow' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.building_works_breach_provide_copy === 'attached'`),
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.1.6.2.1) Please provide a copy of any supporting correspondence', required: true },
                                    children: [
                                      {
                                        $formkit: 'file-upload',
                                        name: `${parentPath}.building_works_breach_files`,
                                        fileName: `${parentPath}.building_works_breach_files`,
                                        validation: 'required',
                                        validationLabel: 'File upload',
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },

            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.1.2) Has there been any change of use?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.change_of_use`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.change_of_use === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.1.2.1) Please provide the dates, the details, and state whether an application for change of use was made to the local authority and attach Building Regulations and Completion certificates or explain why if none were required. For each change, outline the nature of the work and confirm the year the change was completed',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.change_of_use_details`,
                                inputType: 'textarea',
                                rows: 6,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.2.2) Was the change of use made during your ownership?', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.change_of_use_during_ownership`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.change_of_use_during_ownership === 'yes'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label:
                                        '(4.1.2.2.1) Please supply copies of the Planning Permission and Building Regulations and Completion certificates (if applicable)',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.change_of_use_certificate`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Attached', value: 'attached' },
                                          { label: "Can't locate", value: 'cantLocate' },
                                          { label: 'Did not obtain', value: 'didNotObtain' },
                                          { label: 'To Follow', value: 'toFollow' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.change_of_use_certificate === 'attached'`),
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: '(4.1.2.2.1.1) Please provide a copy of the relevant permission(s) and consent(s)',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'file-upload',
                                        name: `${parentPath}.change_of_use_certificate_files`,
                                        fileName: `${parentPath}.change_of_use_certificate_files`,
                                        validation: 'required',
                                        validationLabel: 'File upload',
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },

            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.1.3) Since 1st April 2002 has replacement of any windows, roof windows, roof lights, glazed doors taken place?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.windows_replacement_taken_place`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.windows_replacement_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.1.3.1) Please indicate which alteration you have made, and confirm if you will attach planning permission (if applicable), Building Regulations and Completion certificate for each change.',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.windows_replacement_description`,
                                inputType: 'textarea',
                                rows: 6,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.3.2) Were these made changes made during your ownership ?', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.windows_replacement_during_ownership`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.windows_replacement_during_ownership === 'yes'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.3.2.1) Do you remember the date you made this change?', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.windows_replacement_date_known`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    $cmp: 'el-form-item',
                                    if: isTrueCondition(`${parentPath}.windows_replacement_date_known === 'yes'`),
                                    children: [
                                      DatePicker(
                                        '(4.1.3.2.1.1) What was the date of the alteration?',
                                        `${parentPath}.windows_replacement_date`,
                                        true,
                                        '!max-w-[450px]',
                                      ),
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.windows_replacement_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.1.3.3) Please supply a copy of the Building Regulations and Completion certificate for the building works',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.windows_replacement_certificate`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.windows_replacement_certificate === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.3.3.1) Please provide a copy of the completion certificate(s)', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.windows_replacement_certificate_files`,
                                fileName: `${parentPath}.windows_replacement_certificate_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.windows_replacement_taken_place === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $el: 'div',
                            attrs: {
                              class: 'formkit-label mb-2',
                            },
                            children: [
                              {
                                $el: 'span',
                                children: '* ',
                                attrs: {
                                  class: 'text-red-400',
                                },
                              },
                              '(4.1.3.4)  Please set out details in box below for each alteration, if more than one:',
                            ],
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: 'text-sm pl-8',
                            },
                            children: [
                              {
                                $el: 'div',
                                children: 'i). Breach of Building Regulations consent conditions if any,',
                              },
                              {
                                $el: 'div',
                                children: 'ii). Work which that has not met the Building Regulation consent conditions, if any,',
                              },
                              {
                                $el: 'div',
                                children: 'iii). Any Building Regulation control issues that need to be resolved.',
                              },
                            ],
                          },
                          {
                            $cmp: 'el-form-item',
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.windows_replacement_breach`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.windows_replacement_breach === 'yes'`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.1.3.4.1) Please give details of any works being referred to or issues that need to be resolved.',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.windows_replacement_breach_details`,
                                inputType: 'textarea',
                                rows: 6,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },

            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.1.4) Has a conservatory been added?', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.conservatory_added`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        validationVisibility: 'live',
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.conservatory_added === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.1.4.1) Was the conservatory added during your ownership?', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.conservatory_added_during_ownership`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.conservatory_added_during_ownership === 'yes'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: { label: '(4.1.4.1.1) Do you remember the date that you added the conservatory?', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.conservatory_added_date_known`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    $cmp: 'el-form-item',
                                    if: isTrueCondition(`${parentPath}.conservatory_added_date_known === 'yes'`),
                                    children: [
                                      DatePicker(
                                        '(4.1.4.1.1.1) What is the date of alteration?',
                                        `${parentPath}.conservatory_added_date`,
                                        true,
                                        '!max-w-[450px]',
                                      ),
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.conservatory_added === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.1.4.2) Was the change made under permitted development, if not, you will need to supply Building Regulations and Completion certificate',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.conservatory_added_permitted_development`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.conservatory_added_permitted_development === 'no'`),
                            $cmp: 'ElRow',
                            props: { gutter: 20, class: 'pl-8' },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 24 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label:
                                        '(4.1.4.2.1) Please supply Building Regulations and Completion certificate, and planning permission if it was needed.',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.conservatory_added_certificate`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Attached', value: 'attached' },
                                          { label: "Can't locate", value: 'cantLocate' },
                                          { label: 'Did not obtain', value: 'didNotObtain' },
                                          { label: 'To Follow', value: 'toFollow' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.conservatory_added_certificate === 'attached'`),
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: '(4.1.4.2.1.1) Please provide a copy of the completion certificate and planning permission',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'file-upload',
                                        name: `${parentPath}.conservatory_added_certificate_files`,
                                        fileName: `${parentPath}.conservatory_added_certificate_files`,
                                        validation: 'required',
                                        validationLabel: 'File upload',
                                        validationVisibility: 'live',
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.conservatory_added === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label:
                                '(4.1.4.3) Are you aware of any breaches of planning permission conditions or building regulations consent conditions or work not having the necessary consents?.  Are you aware of any unresolved planning or building control issues?',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.conservatory_added_breach`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.conservatory_added_breach === 'yes'`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(4.1.4.3.1) Please describe the alterations that you are referring to and provide details of any breach of conditions.',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-input',
                                name: `${parentPath}.conservatory_added_breach_details`,
                                inputType: 'textarea',
                                rows: 6,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                validationVisibility: 'live',
                              },
                            ],
                          },
                          {
                            if: isBaspiOnly(),
                            $el: 'div',
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.conservatory_added_breach === 'yes'`),
                                $el: 'div',
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label:
                                        '(4.1.4.3.2) Confirm whether you will pay for an insurance policy if the *Local Authority still have power to enforce the breach or whether you already have an indemnity policy in place from when you purchased',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.confirm_insurance_policy`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        validationVisibility: 'live',
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.confirm_insurance_policy === 'yes'`),
                                    $el: 'div',
                                    children: [
                                      {
                                        $cmp: 'el-form-item',
                                        props: {
                                          label: '(4.1.4.3.2.1) Please supply a copy of the indemnity insurance already in place',
                                          required: true,
                                        },
                                        children: [
                                          {
                                            $formkit: 'el-radio-group',
                                            name: `${parentPath}.indemnity_insurance_policy`,
                                            validation: 'required',
                                            validationMessages: {
                                              required: 'This field is required',
                                            },
                                            validationVisibility: 'live',
                                            options: [
                                              { label: 'Attached', value: 'attached' },
                                              { label: "Can't locate", value: 'cantLocate' },
                                              { label: 'Did not obtain', value: 'didNotObtain' },
                                              { label: 'To Follow', value: 'toFollow' },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        if: isTrueCondition(`${parentPath}.indemnity_insurance_policy === 'attached'`),
                                        $cmp: 'el-form-item',
                                        props: { label: '(4.1.4.3.2.1.1) Please provide a copy of the indemnity insurance already in place', required: true },
                                        children: [
                                          {
                                            $formkit: 'file-upload',
                                            name: `${parentPath}.indemnity_insurance_policy_files`,
                                            fileName: `${parentPath}.indemnity_insurance_policy_files`,
                                            validation: 'required',
                                            validationLabel: 'File upload',
                                            validationVisibility: 'live',
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
